import { getTeamsApi, getTeamsBySearchApi, getTeamByIdApi, getAvailableMembers } from '@api/livechat/TeamManagement';
import { MEMBER_LIST_PAGE_SIZE } from '@configs/constants';
import { AnyEventObject } from 'xstate';
import { IEditTeamEditorContext } from '../EditTeamEditor/editTeamEditorMachine';
import { IControllerContext } from '../TeamManagementPane/teamManagementMachine';

export const getTeams = (context: IControllerContext, event: AnyEventObject) => {
  const { searchTxt, teamCurrentPage } = context;
  if (searchTxt) {
    return getTeamsBySearchApi(searchTxt);
  }
  return getTeamsApi(teamCurrentPage);
};

export const getTeamById = (context: IEditTeamEditorContext, event: AnyEventObject) => {
  const { teamId } = context;
  return getTeamByIdApi(teamId);
};

export const getTeamData = async (context: IEditTeamEditorContext, event: AnyEventObject) => {
  try {
    const { teamId } = context;
    const { page } = event;
    const { data: teamResult } = await getTeamByIdApi(teamId);
    const { data: members } = await getAvailableMembers(page, MEMBER_LIST_PAGE_SIZE, [teamId]);
    return {
      teamResult,
      members,
    };
  } catch (err) {
    throw err;
  }
};
