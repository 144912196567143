import React from 'react';
import { Container } from './style';
import useComposer from './useComposer';
import MessageComposer from './MessageComposer';
import { InfoToast, WarningToast } from '@components/Toast';
import useTranslation from '@hooks/useTranslation';
import { isTicketOpen } from '@utils/ticketStatus';

const Composer: React.FC = () => {
  const t = useTranslation();
  const { selectedTicket, canChat } = useComposer();
  const _renderComposer = () => {
    if (!selectedTicket) return null;
    if (isTicketOpen(selectedTicket)) {
      return (
        <WarningToast align="flex-start">{t('livechat.ticket.management.ticket.console.warning.toast')}</WarningToast>
      );
    }
    if (!canChat()) {
      return <InfoToast align="flex-start">{t('livechat-composer-info-not-my-own-ticket')}</InfoToast>;
    }

    return <MessageComposer />;
  };
  return <Container>{_renderComposer()}</Container>;
};

export default Composer;
