import { getTicket } from '@api/livechat/EngagementUserList';
import { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import { ConcatTaskDataEvent, IUserListMachineContext, UpdateTaskDataEvent } from '../userListMachine.type';
import { isEmpty } from 'lodash';

export type GetAllTicketOption = {
  nid: string;
  memberId: string;
  channelsId: string[];
  task: string;
  teamId: string[];
  cancelToken: CancelTokenSource;
  size?: number;
  sort?: string;
  lastTicketTIme?: number;
};

export const getAllTickets: (
  context: IUserListMachineContext,
  cancelToken: CancelTokenSource,
) => Promise<UpdateTaskDataEvent | ConcatTaskDataEvent> = async (context, cancelToken) => {
  const { channels, member, status: task } = context.filterParams;
  const { _id: memberId, nid, teamId } = member;
  const taskList = context.taskData.results;
  const lastTaskTime =
    taskList.length > 0 ? dayjs(taskList[taskList.length - 1].ticket.createdAt).valueOf() : undefined;
  const response = await getTicket(
    memberId,
    undefined,
    nid,
    'desc',
    isEmpty(channels) ? '_all' : channels.map((channel) => channel.id).join(','),
    task,
    teamId,
    cancelToken,
    lastTaskTime,
  );
  if (lastTaskTime) {
    return {
      type: 'CONCAT_TASK_DATA',
      value: response.data,
    };
  } else {
    return {
      type: 'FETCH_TASK_DATA',
      value: response.data,
    };
  }
};
