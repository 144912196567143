import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import MemberListItem from '@pages/components/MemberListItem';
import { IMember, ITeam } from '@types';
import { Container, SheetHeader, TabContainer, SearchInputContainer, CloseBtn, SearchBtn, BackBtn } from './style';
import { Text } from '@components/UtilsComponent';
import Tab from '@components/Tab';
import useTranslation from '@hooks/useTranslation';
import TeamListItem from '@pages/components/TeamListItem';
import LoadingCircle from '@components/Loading';
import SearchInput from '@components/SearchInput';
import { useMember } from '@providers/MemberProvider';
export type IAssineeTab = {
  members: IMember[];
  teams: ITeam[];
  height?: number;
  isLoading: boolean;
  handleTabChange: (tabName: string) => void;
  handleSelectMember: (member: IMember) => void;
  handleSelectTeam: (team: ITeam) => void;
  handleOnDismiss: () => void;
};

const AssineeTab: React.FC<IAssineeTab> = ({
  members,
  teams,
  height,
  isLoading,
  handleTabChange,
  handleSelectMember,
  handleSelectTeam,
  handleOnDismiss,
}: IAssineeTab) => {
  const t = useTranslation();
  const { member: currentMember } = useMember();
  const [keywords, setKeyword] = useState('');
  const [toggle, setToggle] = useState(false);

  const _renderPeople = () => {
    return {
      tab: 'People',
      key: 'member',
      children: (
        <TabContainer height={height}>
          {isLoading ? (
            <LoadingCircle />
          ) : (
            members
              .filter((member) => {
                return (
                  member._id !== currentMember._id && member.username.toLowerCase().includes(keywords.toLowerCase())
                );
              })
              .map((member: IMember) => (
                <MemberListItem key={member._id} member={member} onSelect={() => handleSelectMember(member)} />
              ))
          )}
        </TabContainer>
      ),
    };
  };

  const _renderTeam = () => {
    return {
      tab: 'Teams',
      key: 'team',
      children: (
        <TabContainer height={height}>
          {isLoading ? (
            <LoadingCircle />
          ) : (
            teams
              .filter((team) => {
                return team.name.toLowerCase().includes(keywords.toLowerCase());
              })
              .map((team: ITeam) => <TeamListItem key={team._id} team={team} onSelect={() => handleSelectTeam(team)} />)
          )}
        </TabContainer>
      ),
    };
  };

  return (
    <Container>
      <SheetHeader>
        {toggle ? (
          <>
            <BackBtn onClick={() => setToggle(false)}>
              <FontAwesomeIcon icon={['fas', 'arrow-left']} />
            </BackBtn>
            <SearchInputContainer>
              <SearchInput
                value={keywords}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onClear={() => {
                  setKeyword('');
                }}
              />
            </SearchInputContainer>
          </>
        ) : (
          <>
            <CloseBtn onClick={handleOnDismiss}>
              <FontAwesomeIcon icon={['fas', 'times']} />
            </CloseBtn>
            <Text>{t('livechat.mobile.get.change.assignee.label')}</Text>
            <SearchBtn onClick={() => setToggle(!toggle)}>
              <FontAwesomeIcon icon={['fas', 'search']} />
            </SearchBtn>
          </>
        )}
      </SheetHeader>
      <Tab
        centered
        tabs={[_renderPeople(), _renderTeam()]}
        defaultActiveKey={'member'}
        onTabClick={(key: string) => handleTabChange(key)}
      />
    </Container>
  );
};

export default AssineeTab;
