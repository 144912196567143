import React from 'react';
import { Container, ErrorContainer, RetryButton } from './style';
import LoadingCircle from '@components/Loading';
import { Font12G3W400 } from '@components/UtilsComponent';
import useTranslation from '@hooks/useTranslation';

export type ICardContentProps = {
  isLoading: boolean;
  isError: boolean;
  height?: string;
  onRetry: () => void;
  children?: React.ReactNode;
};

export const CardContent = ({ isLoading, isError, children, height, onRetry }: ICardContentProps) => {
  const t = useTranslation();

  if (isError) {
    return (
      <ErrorContainer>
        <Font12G3W400>{t('operational.dashboard.fetch.error.message')}</Font12G3W400>
        <Font12G3W400>
          <RetryButton onClick={onRetry}>{t('operational.dashboard.fetch.error.retry.button')}</RetryButton>
        </Font12G3W400>
      </ErrorContainer>
    );
  }
  if (isLoading) {
    return (
      <Container height={height}>
        <LoadingCircle />
      </Container>
    );
  }

  return <Container height={height}>{children}</Container>;
};
