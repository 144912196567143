import { client } from '@api';
import { TEAM_LIST_PAGE_SIZE } from '@configs/constants';
import { IFetchFnResponse } from '@hooks/useInfinityLoadByPage';
import { IGetByCursorParams, ITeam } from '@types';
import { qsTransform } from '@utils/qsTransform';
import { CancelTokenSource } from 'axios';

type ITeamPayload = {
  agents: string[];
  default: boolean;
  department: string;
  teamName: string;
};

const teamSortFields = qsTransform('sortFields', ['-default', 'name']);

export const saveTeamApi = (payload: ITeamPayload) => {
  return client.post('/api/teams', payload);
};

export const getTeamsApi = (page = 1, pageSize = TEAM_LIST_PAGE_SIZE) => {
  return client.get<IFetchFnResponse<ITeam>>(`/api/teams?page=${page}&pageSize=${pageSize}&${teamSortFields}`);
};

export const getMyTeam = (params: IGetByCursorParams, cancelToken?: CancelTokenSource) => {
  return client.get('/api/teams/me', {
    params,
    cancelToken: cancelToken?.token,
  });
};

export const getTeamsBySearchApi = (searchTxt: string) => {
  return client.get(`/api/teams/search?keyword=${searchTxt}&${teamSortFields}`);
};

export const deleteTeamApi = (id: string) => {
  return client.delete(`/api/teams/${id}`);
};

export const getTeamByIdApi = (id: string) => {
  return client.get(`/api/teams/${id}?populate=agents`);
};

export const updateTeamApi = (id: string, payload: ITeamPayload) => {
  return client.put(`/api/teams/${id}`, payload);
};
