import { saveDraftWrapup } from '@api/livechat/Ticket';
import {
  DEBOUNCE_TIME,
  PERMISSION_DENIED,
  PERMISSION_DENIED_NAME_ERROR,
  REFRESH_PAGE_BUTTON,
} from '@configs/constants';
import { useAlertModal } from '@providers/AlertModalProvider';
import { ITicket } from '@types';
import { AxiosError } from 'axios';
import { useState, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';
export const useTicketNote = (ticket: ITicket) => {
  const noteTempRef = useRef(ticket?.summary?.note || '');
  const [note, setNote] = useState(ticket?.summary?.note || '');
  const { onOpen, onUpdateDetail } = useAlertModal();
  const history = useHistory();
  const saveNoteMute = useMutation(
    async (note: string) => {
      if (note === noteTempRef.current) {
        return;
      }
      return saveDraftWrapup(ticket._id, {
        note: note,
        reasons: ticket.summary?.reasons || [],
        result: ticket.summary?.result || [],
        categories: ticket.summary?.categories || [],
      });
    },
    {
      retry: 0,
    },
  );
  const debouncedNoteTxt = useDebounce(note, DEBOUNCE_TIME);

  async function updateNote(note: string) {
    try {
      await saveNoteMute.mutateAsync(note);
    } catch (error) {
      const err = error as AxiosError;
      const name = err?.response?.data?.name;
      const errorMessage = err?.response?.data?.message;
      if (name === PERMISSION_DENIED_NAME_ERROR) {
        onUpdateDetail(PERMISSION_DENIED, errorMessage.replace(/\((.*?)\)/, ''), REFRESH_PAGE_BUTTON);
        onOpen(() => {
          history.goBack();
        });
        return;
      }
    }
  }

  useEffect(() => {
    // update note
    updateNote(debouncedNoteTxt);
  }, [debouncedNoteTxt]);

  useEffect(() => {
    // update note
    setNote(ticket?.summary?.note || '');
    noteTempRef.current = ticket?.summary?.note || '';
  }, [ticket.summary?.note]);

  return {
    note,
    saveNoteMute,
    onUpdateNote: (value: string) => {
      setNote(value);
    },
  };
};
