import { client } from '@api';
import { CancelTokenSource } from 'axios';
import qs from 'qs';

export type IBaseAnalyticParams = {
  agents?: string[];
  channels?: string[];
  tags?: string[];
  teams?: string[];
  startIsoStringDate: string;
  endIsoStringDate: string;

  cancelToken?: CancelTokenSource;
};

export const getStatusTicketAnalytic = ({ cancelToken, ...rest }: IBaseAnalyticParams) => {
  const query = qs.stringify(rest);
  return client.get(`/api/v1/analytics/tickets/status?${query}`, {
    cancelToken: cancelToken?.token,
  });
};

export const getTicketActivityAnalytic = ({ cancelToken, ...rest }: IBaseAnalyticParams & { interval: string }) => {
  const query = qs.stringify(rest);
  return client.get(`/api/v1/analytics/tickets/activity?${query}`, {
    cancelToken: cancelToken?.token,
  });
};

export const getAverageTimeAnalytic = ({ cancelToken, ...rest }: IBaseAnalyticParams) => {
  const query = qs.stringify(rest);
  return client.get(`/api/v1/analytics/tickets/time?${query}`, {
    cancelToken: cancelToken?.token,
  });
};

export const getTicketByAgentAnalytic = ({
  cancelToken,
  ...rest
}: IBaseAnalyticParams & { status?: string[]; nextToken?: string; jobId?: string }) => {
  const query = qs.stringify(rest);
  return client.get(`/api/v1/analytics/tickets/agents?${query}`, {
    cancelToken: cancelToken?.token,
  });
};
