import { IListState } from '@pages/components/UserWithSearch';
import { IUser } from '@types';
import EChannelType from '@enums/ChannelType';
import { UserData, MessageData } from '../userListMachine.type';

export type IUserItem = Omit<IUser, 'unreadMessageCount'>;
export type IUserMessageItem = Omit<IUser & { messageId: string }, 'unreadMessageCount'>;

export type UserListDataOption = {
  userData: UserData;
  isFetching: boolean;
  isFetchError: boolean;
  isNoMore: boolean;
};

export type MessageListDataOption = {
  messageData: MessageData;
  keyword: string;
  isFetching: boolean;
  isFetchError: boolean;
  isNoMore: boolean;
};

export const generateUserListData = ({ userData, isFetchError, isFetching, isNoMore }: UserListDataOption) => {
  const userList = userData.userMessages.map(({ id, channelType, followUp, img, message, name }) => {
    return {
      id,
      message,
      img,
      name,
      followUp,
      channelType,
    } as IUserItem;
  });
  return {
    items: userList,
    total: userData.total.value.toString(),
    isFetchError,
    isFetching,
    isNoMore,
  } as IListState;
};

export const generateMessageListData = ({
  messageData,
  isFetchError,
  isFetching,
  isNoMore,
  keyword,
}: MessageListDataOption) => {
  const messageList = messageData.userMessages.map(({ id, channelType, img, message, name, messageId, time }) => {
    return {
      id,
      message,
      img,
      name,
      time,
      messageId,
      channelType: channelType as EChannelType,
    } as IUserMessageItem;
  });
  return {
    items: messageList,
    total: messageData.total.value.toString(),
    isFetchError,
    isFetching,
    isNoMore,
  } as IListState;
};
