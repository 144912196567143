import React from 'react';
import { Checkbox } from '@components/Checkbox';
import FormItem from '@components/FormItem';
import { LabelTitle } from '@components/FormItem/style';
import SearchInput from '@components/SearchInput';
import Table from '@components/Table';
import TextInput from '@components/TextInput';
import useTranslation from '@hooks/useTranslation';
import { Breadcrumb, Col, Form, Row, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  Container,
  FormHeader,
  Description,
  CheckBoxWrap,
  Item,
  TableWrap,
  SearchWrap,
  ActionWrap,
  UsernameWrap,
  AvatarWrap,
  DetailWrap,
} from './style';
import useNewTeamEditor from './useNewTeamEditor';
import { Button, PrimaryButton } from '@components/Button';
import { DangerToast } from '@components/Toast';
import LoadingIcon from '@components/LoadingIcon';
import { MEMBER_LIST_PAGE_SIZE } from '@configs/constants';
import dayjs from 'dayjs';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import Avatar from '@components/Avatar';
import { ToastWrap } from '@components/Toast/styles';
import { IMember } from '@types';

const NewTeamEditor: React.FC = () => {
  const t = useTranslation();
  const history = useHistory();
  const {
    form,
    current,
    send,
    onSubmit,
    filterOutSelectedBotMembers,
    handleSelectMembers,
    handlePageChange,
    isCheckBoxDisabled,
  } = useNewTeamEditor();
  const { selectedMembers, availableMembers, searchTxt, currentPage, errorMessage } = current.context;
  const MEMBER_COLUMNS = [
    {
      title: 'USERNAME',
      dataIndex: 'username',
      key: 'username',
      width: 200,
      // eslint-disable-next-line react/display-name
      render: (text: string, record: IMember) => {
        return (
          <UsernameWrap>
            <AvatarWrap>
              <Avatar name={record.username} width={32} height={32} memberStatus={record.status} />
            </AvatarWrap>
            <DetailWrap>
              <Typography.Text>{record.username}</Typography.Text>
              <Typography.Text>{record.email}</Typography.Text>
            </DetailWrap>
          </UsernameWrap>
        );
      },
    },
    {
      title: 'PHONE NO.',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      title: 'CREATE DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (text: string, { createdAt }: { createdAt: string }, index: number) => {
        return dayjs(createdAt).format('DD MMM YYYY');
      },
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role',
      width: 200,
      render: (text: string, record: any, index: number) => {
        return record.roles.join(',');
      },
    },
  ];

  return (
    <Container>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to="/team-management">{t('livechat.team.management.member.editor.breadcrumb.page.before')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item href="">
          {t('livechat.team.management.team.editor.breadcrumb.page.current.new')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col span={22} offset={2}>
          <Row>
            <FormHeader>
              <Typography.Title level={3}>{t('livechat.team.management.team.editor.title')}</Typography.Title>
            </FormHeader>

            <Form layout="vertical" onFinish={onSubmit} form={form}>
              <Col span={10}>
                {errorMessage && (
                  <ToastWrap>
                    <DangerToast align="flex-start">{errorMessage}</DangerToast>
                  </ToastWrap>
                )}
                <FormItem
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('livechat.team.management.team.editor.name.required'),
                    },
                  ]}
                  label={
                    <>
                      <LabelTitle>{t('livechat.team.management.team.editor.name.label')}</LabelTitle>
                    </>
                  }
                >
                  <TextInput size="large" placeholder={t('livechat.team.management.team.editor.name.placeholder')} />
                </FormItem>
                <FormItem
                  name="department"
                  label={
                    <>
                      <LabelTitle>{t('livechat.team.management.team.editor.department.label')}</LabelTitle>
                    </>
                  }
                >
                  <TextInput
                    size="large"
                    placeholder={t('livechat.team.management.team.editor.department.placeholder')}
                  />
                </FormItem>
                <CheckBoxWrap>
                  <Item>
                    <FormItem noStyle name="default" valuePropName="checked">
                      <Checkbox onChange={filterOutSelectedBotMembers} />
                    </FormItem>
                  </Item>
                  <Item>
                    <LabelTitle>{t('livechat.team.management.team.editor.set.default.label')}</LabelTitle>
                    <Description>{t('livechat.team.management.team.editor.set.default.label.description')}</Description>
                  </Item>
                </CheckBoxWrap>
              </Col>
              <Col span={22}>
                <TableWrap>
                  <Item>
                    <LabelTitle>{t('livechat.team.management.team.editor.add.member.label')}</LabelTitle>
                    <Description>
                      {t('livechat.team.management.team.editor.add.member.description')}
                      <Link to="/team-management/new-member">
                        {t('livechat.team.management.team.editor.add.member.link')}
                      </Link>
                    </Description>
                  </Item>
                  <SearchWrap>
                    <SearchInput
                      value={searchTxt}
                      size="large"
                      onClear={() => send('SEARCH_TEXT_CHANGE', { value: '' })}
                      onChange={(e: any) => send('SEARCH_TEXT_CHANGE', { value: e.target.value })}
                    />
                  </SearchWrap>
                  <Table
                    scroll={{ y: 'calc(100vh - 350px)' }}
                    columns={MEMBER_COLUMNS}
                    header={<></>}
                    data={availableMembers.rows}
                    rowKey={({ _id }: { _id: string }) => _id}
                    rowSelection={{
                      getCheckboxProps(record) {
                        return {
                          disabled: isCheckBoxDisabled(record),
                        };
                      },
                      type: 'checkbox',
                      onChange: handleSelectMembers,
                      preserveSelectedRowKeys: true,
                      selectedRowKeys: selectedMembers,
                      // getCheckboxProps: (record: any) => ({
                      //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
                      //   name: record.name,
                      // }),
                    }}
                    loading={
                      current.matches('fetchingAvailableMembers') && {
                        indicator: (
                          <LoadingWrap>
                            <LoadingCircle />
                          </LoadingWrap>
                        ),
                      }
                    }
                    pagination={{
                      defaultPageSize: MEMBER_LIST_PAGE_SIZE,
                      current: currentPage,
                      total: availableMembers.total,
                      onChange: handlePageChange,
                      showSizeChanger: false,
                    }}
                  />
                </TableWrap>
              </Col>
              <ActionWrap>
                <Button onClick={() => history.push('/team-management')} size="large">
                  {t('livechat.team.management.team.editor.back.btn')}
                </Button>
                <PrimaryButton htmlType="submit" size="large" disabled={current.matches('saving')}>
                  {current.matches('saving') && <LoadingIcon icon={['fas', 'sync-alt']} />}
                  {t('livechat.team.management.team.editor.submit.btn')}
                </PrimaryButton>
              </ActionWrap>
            </Form>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default NewTeamEditor;
