/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IAssignedTicket, IOpenedTicket, IResolvedTicket } from '@types';
import EChannelType from '@enums/ChannelType';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { client } from '../../index';
import { ETicketStatus } from '@enums/TicketStatus';
import { compact } from 'lodash';
import { qsTransform } from '@utils/qsTransform';

export const getUserListBySearch = (
  keyword: string,
  agentId: string,
  channelIds: string,
  task: string,
  nid: string,
  searchAfter?: number,
) => {
  if (searchAfter) {
    return client.post('/api/users/search-user-name', {
      keyword,
      agentId,
      channelIds: compact(channelIds.split(',')),
      task,
      networkId: nid,
      searchAfter,
    });
  }
  return client.post('/api/users/search-user-name', {
    keyword,
    agentId,
    channelIds: compact(channelIds.split(',')),
    task,
    networkId: nid,
  });
};

export const getUserMessageListBySearch = (
  keyword: string,
  agentId: string,
  channelIds: string,
  task: string,
  nid: string,
  searchAfter?: number,
) => {
  if (searchAfter) {
    return client.post('/api/messages/search-user-messages', {
      keyword,
      agentId,
      channelIds: compact(channelIds.split(',')),
      task,
      nid,
      searchAfter,
    });
  }
  return client.post('/api/messages/search-user-messages', {
    keyword,
    agentId,
    channelIds: compact(channelIds.split(',')),
    task,
    nid,
  });
};

export const getUserById = (id: string) => {
  if (!id) {
    return null;
  }
  return { data: {} };
};

export type ILastMessage = {
  payload: Record<string, unknown>;
  createdAt: number;
  userId: string;
  channelId: string;
  channelType: EChannelType;
  ticketId: string;
};

export type IEngageUser = {
  _id: string;
  image: string;
  name: string;
  displayName?: string | null;
  referenceId: string;
};

export type ITicketInfo = IOpenedTicket | IAssignedTicket | IResolvedTicket;

export type ITaskCount = {
  assignedToMe: number;
  assignedToTeam: number;
  followUps: number;
  unAssigned: number;
};

export type UserTaskData = {
  isRead?: boolean;
  message: ILastMessage;
  user: IEngageUser;
  ticket: ITicketInfo;
};

export type UserTaskDataResponse = {
  total: number;
  results: UserTaskData[];
  task_count: ITaskCount;
};

export const getTicket = (
  agentId: string,
  size = 30,
  nid: string,
  sort = 'desc',
  channelIds: string,
  task: string,
  teamId: string[],
  cancelToken: CancelTokenSource,
  lastTicketTime?: number,
  filterByUnreplied?: boolean,
  excludeTeamIds?: string[],
  filterNoTeam?: boolean,
) => {
  const status = task === 'unAssigned' ? undefined : ETicketStatus.ASSIGNED;
  const teamQuery = qsTransform('teamIds', teamId);
  // remove teamId from excludeTeamIds
  let excludeTeamIdsArr: string[] = [];
  if (excludeTeamIds) {
    excludeTeamIdsArr = excludeTeamIds.filter((id) => !teamId.includes(id));
  }
  const excludeQuery = excludeTeamIdsArr ? qsTransform('excludeTeamIds', excludeTeamIdsArr) : undefined;
  if (lastTicketTime) {
    return client.get(`/api/tickets/tasks?${teamQuery}`, {
      cancelToken: cancelToken.token,
      params: {
        size,
        nid,
        sort,
        channelId: channelIds,
        agentId,
        task,
        teamId: teamId.join(','),
        status: status,
        'pagination[latestMessageAt]': lastTicketTime,
        filterByUnreplied,
        filterNoTeam,
      },
    }) as Promise<AxiosResponse<UserTaskDataResponse>>;
  }

  return client.get(
    `/api/tickets/tasks?size=${size}&nid=${nid}&sort=${sort}&channelId=${channelIds}&agentId=${agentId}&task=${task}&${teamQuery}&${excludeQuery}`,
    {
      params: {
        status: status,
        filterByUnreplied,
        filterNoTeam,
      },
      cancelToken: cancelToken.token,
    },
  ) as Promise<AxiosResponse<UserTaskDataResponse>>;
};
