import React, { useMemo } from 'react';
import useMessageComposer from './useMessageComposer';
import { useFile } from '@providers/MessageBoxProvider/useFile';
import MessageComposerComp from '@pages/components/MessageComposer';
import { useComposerManager } from '@providers/ComposerManagerProvider';
import { useRecoilValue } from 'recoil';
import { selectedTicketState, selectedUserState } from '@store/EngagementUserListAtom';
import { getInputMaxLength } from '@utils/getInputMaxLength';

const MessageComposer: React.FC = () => {
  const { preview, composer } = useComposerManager();
  const { onSelectTemplate } = preview;
  const { onToggleComposer } = composer;
  const { txt, handleInputChange, handleSendMessage, handleSendTemplate } = useMessageComposer();
  const { fileHandler } = useFile((onUploadFile) => {
    onUploadFile();
  });
  const selectedUser = useRecoilValue(selectedUserState);
  const selectedTicket = useRecoilValue(selectedTicketState);

  const inputMaxLength = useMemo(() => {
    return getInputMaxLength(selectedTicket?.channelType);
  }, [selectedTicket]);

  return (
    <MessageComposerComp
      platform={selectedUser?.channel?.type.toLocaleLowerCase()}
      maxLength={inputMaxLength}
      messageBoxHandler={{
        textMessage: txt,
        disabledSendButton: txt.length === 0 || txt.trim() === '',
        handleTextInputChange: handleInputChange,
        handleSendMessage: handleSendMessage,
        handleSendTemplate: (template) => {
          handleSendTemplate(template);
          // close composer
          onToggleComposer();
          // close preview
          onSelectTemplate(null);
        },
      }}
      fileHandler={fileHandler}
    />
  );
};

export default MessageComposer;
