import { useState, useRef } from 'react';
import { MESSAGE_BOX_OPTIONS, useTicketHistoryContext } from '..';
import { useActor } from '@xstate/react';
import { useMessageBox } from '@providers/MessageBoxProvider';
import { IFileHandler, IMessageBoxHandler } from '@types';
import { ETicketStatus } from '@enums/TicketStatus';
import { assignTicketToAdmin, assignTicketToAgent, openTicket } from '@api/livechat/Ticket';
import { useMember } from '@providers/MemberProvider';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useTranslation from '@hooks/useTranslation';

enum EAnswerState {
  IDLE = 'idle',
  TEXT_STATE = 'textState',
  FILE_STATE = 'fileState',
}

export const useTicketHistoryChatPane = () => {
  const t = useTranslation();
  const alert = useAlert();
  const anwserState = useRef<EAnswerState>(EAnswerState.IDLE);
  const [assigningTicket, setAssigningTicket] = useState(false);
  const [assignSelfModal, setAssignSelfModal] = useState(false);
  const { member } = useMember();
  const { ticketHistoryService } = useTicketHistoryContext();
  const [current] = useActor(ticketHistoryService);
  const { send: ticketHistorySend } = ticketHistoryService;
  const ticketContext = current.context;
  const {
    current: messageCurrent,
    messageContext,
    uploadRef,
    disabledSendButton,
    beforeUpload,
    handleCancelUpload,
    handleUploadFile,
    handleTextInputChange,
    handleSendMessage,
    handleSendTemplate,
  } = useMessageBox();
  const history = useHistory();
  const fileHandler: IFileHandler = {
    isFileProcessing: messageCurrent.hasTag('file-processing'),
    isFileUploading:
      messageCurrent.matches({ chatHistoryState: 'uploadingFiles' }) ||
      messageCurrent.matches({ chatHistoryState: 'uploadFilesSuccess' }) ||
      messageCurrent.matches({ chatHistoryState: 'sendingFilesMessage' }) ||
      messageCurrent.matches({ chatHistoryState: 'sendFilesMessageWithTime' }),

    isFileUploadFailed:
      messageCurrent.matches({ chatHistoryState: 'uploadFilesFailed' }) ||
      messageCurrent.matches({ chatHistoryState: 'sendFilesMessageFailed' }),
    selectedFiles: messageContext.selectedFiles,
    rejectedFiles: messageContext.rejectFiles,
    errorMessage: messageContext.fileErrorMessage,
    uploadRef: uploadRef,
    onConfirmUpload: () => {
      // if user is in ticket management mode
      if (MESSAGE_BOX_OPTIONS.isTicketManagementMode) {
        // status is open
        if (ticketContext.currentTicket.status === ETicketStatus.OPEN) {
          // display modal to assign ticket to self
          anwserState.current = EAnswerState.FILE_STATE;
          setAssignSelfModal(!assignSelfModal);
          return;
        }
      }
      handleUploadFile();
    },
    onCancelUpload: handleCancelUpload,
    beforeUpload,
  };
  const messageBoxHandler: IMessageBoxHandler = {
    textMessage: messageContext.textMessage,
    disabledSendButton: disabledSendButton,
    handleTextInputChange,
    handleSendMessage: async (text: string) => {
      try {
        // if user is in ticket management mode
        if (MESSAGE_BOX_OPTIONS.isTicketManagementMode) {
          // status is open
          if (ticketContext.currentTicket.status === ETicketStatus.OPEN) {
            // display modal to assign ticket to self
            anwserState.current = EAnswerState.TEXT_STATE;
            setAssignSelfModal(!assignSelfModal);
            return;
          }
        }
        handleSendMessage(text);
      } catch (error) {
        console.error(error);
      }
    },
    handleSendTemplate: handleSendTemplate,
  };
  return {
    assigningTicket,
    assignSelfModal,
    ticketContext,
    messageContext,
    isAskReopenTicket: current.matches('askReopenTicket'),
    isAskOpenNewTicket: current.matches('askOpenNewTicket'),
    isReopeningTicket: current.matches('reOpenningTicket'),
    isOpeningNewTicket: current.matches('openningTicket'),
    onOpenNewTicketWithTeam: async (teamId: string) => {
      try {
        setAssigningTicket(true);
        // wait for 2 second
        await openTicket(ticketContext.currentTicket.userId, teamId);
        alert.success(
          t('livechat.ticket.management.alert.reopen.success', {
            ticketNumber: ticketContext.currentTicket.ticketNumber,
          }),
        );
        history.push(`/recents/${ticketContext.currentTicket.userId}`);
      } catch (error) {
        ticketHistorySend('CANCEL');
        if (axios.isAxiosError(error)) {
          // Access to config, request, and response
          alert.error(error?.response?.data.message ?? '');
          setAssigningTicket(false);
        } else {
          // Just a stock error
          setAssigningTicket(false);
          alert.error(t('general.error.title'));
        }
      }
    },
    onConfirmAssignTicket: async () => {
      try {
        if (anwserState.current === EAnswerState.TEXT_STATE) {
          setAssigningTicket(true);
          await assignTicketToAdmin(ticketContext.currentTicket._id, {
            agentId: member._id,
          });
          setAssigningTicket(false);
          setAssignSelfModal(false);
          ticketHistorySend('FETCH_CURRENT_TICKET');
          handleSendMessage(messageContext.textMessage);
          alert.success(`Ticket ${ticketContext.currentTicket.ticketNumber} assigned successfully`);
          history.go(0);
          return;
        }
        if (anwserState.current === EAnswerState.FILE_STATE) {
          setAssigningTicket(true);
          await assignTicketToAdmin(ticketContext.currentTicket._id, {
            agentId: member._id,
          });
          setAssignSelfModal(false);
          setAssigningTicket(false);
          ticketHistorySend('FETCH_CURRENT_TICKET');
          handleUploadFile();
          alert.success(`Ticket ${ticketContext.currentTicket.ticketNumber} assigned successfully`);
          history.go(0);
        }
      } catch (error) {
        setAssigningTicket(false);
        setAssignSelfModal(false);
      }
    },
    onCloseAssignModal: () => {
      anwserState.current = EAnswerState.IDLE;
      setAssignSelfModal(false);
    },

    onToggleConfirmModal: () => {
      setAssignSelfModal(!assignSelfModal);
    },
    onReopenTicket: () => {
      ticketHistorySend('RE_OPEN_TICKET');
    },
    onOpenNewTicket: () => {
      ticketHistorySend('OPEN_TICKET');
    },
    onCancel: () => {
      ticketHistorySend('CANCEL');
    },
    onConfirm: () => {
      ticketHistorySend('CONFIRM');
    },
    fileHandler,
    messageBoxHandler,
  };
};
