import React, { useMemo } from 'react';
import LoadingCircle from '@components/Loading';
import Table from '@components/Table';
import { TEAM_LIST_PAGE_SIZE, TEAM_MANAGEMENT_TEAM_LIST_ACTIONS } from '@configs/constants';
import { Container, LoadingWrap, IconWrap, TeamNameWrap } from './style';
import { DropdownGroup } from '@components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Typography } from 'antd';

export type ITeamList = {
  [key: string]: any;
};

const TeamList: React.FC<ITeamList> = ({
  teamData,
  teamCurrentPage,
  handleSelectAction,
  isLoading,
  handlePageChange,
}: ITeamList) => {
  const TEAM_COLUMNS = useMemo(() => {
    return [
      {
        // eslint-disable-next-line react/display-name
        title: 'TEAM NAME',
        dataIndex: 'name',
        key: 'name',
        // eslint-disable-next-line react/display-name
        render: (text: string, record: any, index: number) => {
          return (
            <TeamNameWrap>
              <Typography.Text>{record.name}</Typography.Text>
              {record.default && <Typography.Text>Default team</Typography.Text>}
            </TeamNameWrap>
          );
        },
      },
      {
        title: 'DEPARTMENT',
        dataIndex: 'department',
        key: 'department',
      },
      {
        title: 'CREATE DATE',
        dataIndex: 'createdAt',
        key: 'createdAt',
        // eslint-disable-next-line react/display-name
        render: (text: string, { createdAt }: { createdAt: string }, index: number) => {
          return <>{createdAt && <span>{dayjs(createdAt).format('DD MMM YYYY')}</span>}</>;
        },
      },
      {
        title: 'MEMBERS',
        dataIndex: 'agents',
        key: 'agents',
        render: (text: string, record: any, index: number) => {
          return record.agents.length;
        },
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: 57,
        // eslint-disable-next-line react/display-name
        render: (text: string, record: any, index: number) => (
          <DropdownGroup
            placement="bottomLeft"
            btn={(setFunction: any) => {
              return (
                <IconWrap>
                  <FontAwesomeIcon
                    onClick={() => setFunction(true)}
                    color="#757575"
                    icon={['far', 'ellipsis-h']}
                    style={{ cursor: 'pointer' }}
                  />
                </IconWrap>
              );
            }}
            data={TEAM_MANAGEMENT_TEAM_LIST_ACTIONS}
            handleSelectItem={(item) => handleSelectAction(item, record)}
          />
        ),
      },
    ];
  }, [teamData]);

  return (
    <Container>
      <Table
        scroll={{ y: 'calc(100vh - 350px)' }}
        columns={TEAM_COLUMNS}
        header={<></>}
        data={teamData.rows}
        loading={
          isLoading && {
            indicator: (
              <LoadingWrap>
                <LoadingCircle />
              </LoadingWrap>
            ),
          }
        }
        pagination={{
          defaultPageSize: TEAM_LIST_PAGE_SIZE,
          current: teamCurrentPage,
          total: teamData.total,
          onChange: handlePageChange,
          showSizeChanger: false,
        }}
      ></Table>
    </Container>
  );
};

export default TeamList;
