import React, { useCallback } from 'react';
import { Modal, ItemWrapWithImage, ItemWrapWithText, ActionContainer, LoadingIcon } from './style';
import { RcFile } from 'antd/lib/upload';
import { Button, PrimaryButton } from '@components/Button';
import useTranslation from '@hooks/useTranslation';
import StateMessage from '@components/StateMessage';
import Error from '@assets/images/error_message.svg';
import { FILE_TYPE } from '@configs/fileType';

export type IPreviewFileModal = {
  visible: boolean;
  files: RcFile[];
  rejectFiles: File[];
  isFailed: boolean;
  isLoading: boolean;
  errorMessage?: string;
  onConfirmUpload: () => void;
  onCancelUpload: () => void;
};

const PreviewFileModal: React.FC<IPreviewFileModal> = ({
  visible,
  files,
  rejectFiles,
  isLoading,
  isFailed,
  errorMessage,
  onConfirmUpload,
  onCancelUpload,
}: IPreviewFileModal) => {
  const t = useTranslation();

  const _renderContent = useCallback(() => {
    let message = '';
    if (isFailed && rejectFiles.length > 0) {
      message = `${t('livechat.message.composer.attach.preview.reject.description')} [${rejectFiles
        .map((f) => f.name)
        .join(', ')}]`;
    } else if (errorMessage) {
      message = errorMessage;
    } else {
      message = t('livechat.message.composer.attach.preview.error.description');
    }

    if (isFailed) {
      return (
        <StateMessage
          label={t('livechat.message.composer.attach.preview.reject.label')}
          type="error"
          description={message}
          img={Error}
          size="medium"
        />
      );
    }

    // dispaly image
    if (files.length === 1 && files[0].type.includes('image') && files[0].type !== FILE_TYPE.PHOTOSHOP) {
      return (
        <ItemWrapWithImage>
          <img data-testid="preview-file-img" src={URL.createObjectURL(files[0])} />
        </ItemWrapWithImage>
      );
    }

    //display 1 file selected
    if (files.length === 1) {
      return (
        <ItemWrapWithText data-testid="preview-file-general">
          {files.length} {t('livechat.message.composer.attach.preview.one.file.selected')}
        </ItemWrapWithText>
      );
    }

    // display number of file selected
    if (files.length > 1) {
      return (
        <ItemWrapWithText data-testid="preview-file-general">
          {files.length} {t('livechat.message.composer.attach.preview.file.selected')}
        </ItemWrapWithText>
      );
    }
    return null;
  }, [rejectFiles, t, files]);
  return (
    <Modal
      visible={visible}
      onCancel={onCancelUpload}
      maskClosable={false}
      footer={
        !isFailed && [
          <ActionContainer key="1">
            <Button size="large" onClick={onCancelUpload} data-testid="composer-cancel-button">
              {t('livechat.message.composer.attach.preview.cancel.button')}
            </Button>
            <PrimaryButton
              form="resolve-ticket-form"
              size="large"
              disabled={isLoading}
              onClick={onConfirmUpload}
              data-testid="composer-confirm-button"
            >
              {isLoading && <LoadingIcon icon={['fas', 'sync-alt']} />}
              {t('livechat.message.composer.attach.preview.send.button')}
            </PrimaryButton>
          </ActionContainer>,
        ]
      }
    >
      {_renderContent()}
    </Modal>
  );
};

export default PreviewFileModal;
