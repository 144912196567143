import _ from 'lodash';
import { createMachine, assign } from 'xstate';
import { getAvailableMembersIncludeTeam, saveTeam, getTeamData, getAllBotMembers } from '../fns';
import { updateTeam } from '../fns/updateTeam';
import { IMemberList, IMember } from '../TeamManagementPane/teamManagementMachine';

export type ITeamData = {
  _id: string;
  name: string;
  department: string;
  default: boolean;
  agents: IMember[];
};

export type IEditTeamEditorContext = {
  teamId: string;
  searchTxt: string;
  availableMembers: IMemberList;
  selectedMembers: string[];
  currentPage: number;
  errorMessage: string;
  teamData: ITeamData;
};

const editTeamEditorMachine = (teamId: string) =>
  createMachine<IEditTeamEditorContext>(
    {
      id: 'team-management-edit-team-editor-machine',
      initial: 'fetchingTeamData',
      context: {
        teamId,
        selectedMembers: [],
        searchTxt: '',
        availableMembers: {} as IMemberList,
        currentPage: 1,
        errorMessage: '',
        teamData: {} as ITeamData,
      },
      states: {
        editing: {
          on: {
            SET_SELECTED_MEMBERS: {
              target: 'editing',
              actions: 'updateSelectedMembers',
            },
            SUBMIT_FORM: {
              target: 'saving',
            },
            FETCH_MEMBER: {
              target: 'fetchingAvailableMembers',
              actions: 'updateCurrentPage',
            },
            FILTER_OUT_BOT_MEMBER: {
              target: 'filteringOutBotMembers',
            },
          },
        },
        fetchingTeamData: {
          invoke: {
            id: 'fetch-team-data',
            src: getTeamData,
            onDone: {
              target: 'fetchTeamDataSuccess',
              actions: ['updateTeamData', 'updateAvailableMembers', 'updateDefaultSelectedMembers'],
            },
            onError: 'fetchTeamDataFailed',
          },
        },
        fetchTeamDataSuccess: {
          after: {
            0: {
              target: 'editing',
            },
          },
        },
        fetchTeamDataFailed: {},
        fetchingAvailableMembers: {
          invoke: {
            id: 'fetch-available-member',
            src: getAvailableMembersIncludeTeam,
            onDone: {
              target: 'editing',
              actions: 'updateAvailableMembersFromSearch',
            },
            onError: 'fetchAvailableMembersFailed',
          },
        },
        fetchAvailableMembersFailed: {},
        debounceFetchMember: {
          on: {
            SEARCH_TEXT_CHANGE: {
              target: 'debounceFetchMember',
              actions: 'updateSearchTxt',
            },
          },
          after: {
            450: {
              target: 'fetchingAvailableMembers',
            },
          },
        },
        saving: {
          invoke: {
            id: 'update-team',
            src: updateTeam,
            onDone: {
              target: 'updateTeamSuccess',
            },
            onError: {
              target: 'editing',
              actions: 'updateErrorMessage',
            },
          },
        },
        updateTeamSuccess: {
          type: 'final',
        },
        filteringOutBotMembers: {
          invoke: {
            id: 'filtering-out-bot-members',
            src: getAllBotMembers,
            onDone: {
              target: 'editing',
              actions: 'postUpdateFilteredBotMembers',
            },
            onError: {
              target: 'editing',
              actions: 'updateErrorMesssage',
            },
          },
        },
      },
      on: {
        SEARCH_TEXT_CHANGE: {
          target: '.debounceFetchMember',
          actions: 'updateSearchTxt',
        },
      },
    },
    {
      actions: {
        updateTeamData: assign({
          teamData: (_context, event) => {
            const { teamResult } = event.data;
            return teamResult;
          },
        }),
        updateSearchTxt: assign({
          searchTxt: (context, event) => {
            return event.value;
          },
        }),
        updateAvailableMembers: assign({
          availableMembers: (context, event) => {
            const { members } = event.data;
            return members;
          },
        }),
        updateAvailableMembersFromSearch: assign({
          availableMembers: (context, event) => {
            const { data } = event.data;
            return data;
          },
        }),
        updateDefaultSelectedMembers: assign({
          selectedMembers: (context, event) => {
            const { teamData } = context;
            const selectedRowKeys = teamData?.agents?.map((agents) => agents?._id);
            return selectedRowKeys;
          },
        }),
        updateSelectedMembers: assign({
          selectedMembers: (context, event) => {
            const { data } = event;
            return data;
          },
        }),
        updateCurrentPage: assign({
          currentPage: (context, event) => {
            return event.page;
          },
        }),
        updateErrorMessage: assign({
          errorMessage: (context, event) => {
            return event.data?.response?.data?.message ?? 'Something went wrong';
          },
        }),
        postUpdateFilteredBotMembers: assign({
          selectedMembers: (context, event) => {
            const bots: (Record<string, unknown> & { _id: string })[] = event.data.data.rows;
            const keys: string[] = bots.map((bot) => bot._id);
            return _.without(context.selectedMembers, ...keys);
          },
        }),
      },
    },
  );

export default editTeamEditorMachine;
