import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Header, LeftSection, RightSection, IconButton, Content, TicketHistoryDivider } from './style';
import { useTicketHistoryItem } from './useTicketHistoryItem';
import { faChevronDown, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { animated } from '@react-spring/web';
export type ITicketHistoryItemProps = {
  header: React.ReactNode;
  children: React.ReactNode;
};

const TicketHistoryItem = ({ header, children }: React.PropsWithChildren<ITicketHistoryItemProps>) => {
  const { wrapperStyle, dividerStyles, chevronStyles, ref, onToggle } = useTicketHistoryItem();
  return (
    <Container style={wrapperStyle}>
      <Header onClick={onToggle}>
        <LeftSection>
          <div>{header}</div>
        </LeftSection>
        <RightSection>
          <IconButton style={chevronStyles}>
            <FontAwesomeIcon icon={faChevronDown} fontSize={20} color="#424242" />
          </IconButton>
        </RightSection>
      </Header>
      <TicketHistoryDivider style={dividerStyles} />
      <Content ref={ref}>{children}</Content>
    </Container>
  );
};

export type ISummaryItemProps = {
  header: React.ReactNode;
  children: React.ReactNode;
};

export const SummaryItem = ({ header, children }: ISummaryItemProps) => {
  const { toggle, wrapperStyle, dividerStyles, ref, onToggle } = useTicketHistoryItem(40, 60);
  return (
    <animated.div
      style={wrapperStyle}
      className="bg-white rounded-[8px] border border-solid border-[#E5E5E5] overflow-hidden"
    >
      <div
        className="cursor-pointer flex px-[12px] py-[8px] justify-between items-center self-stretch"
        onClick={onToggle}
      >
        <div>{header}</div>
        <button>
          {toggle ? (
            <FontAwesomeIcon icon={faMinus} fontSize={12} color="#BDBDBD" />
          ) : (
            <FontAwesomeIcon icon={faPlus} fontSize={12} color="#BDBDBD" />
          )}
        </button>
      </div>
      <TicketHistoryDivider className="mx-[12px]" style={dividerStyles} />
      <div className="px-[12px] py-[8px]" ref={ref}>
        {children}
      </div>
    </animated.div>
  );
};

export default TicketHistoryItem;
