import Avatar from '@components/Avatar';
import { Button, PrimaryButton } from '@components/Button';
import { Checkbox } from '@components/Checkbox';
import FormItem from '@components/FormItem';
import { LabelTitle } from '@components/FormItem/style';
import LoadingCircle from '@components/Loading';
import { LoadingWrap } from '@components/Loading/style';
import LoadingIcon from '@components/LoadingIcon';
import SearchInput from '@components/SearchInput';
import Table from '@components/Table';
import TextInput from '@components/TextInput';
import { DangerToast } from '@components/Toast';
import { ToastWrap } from '@components/Toast/styles';
import { MEMBER_LIST_PAGE_SIZE } from '@configs/constants';
import useTranslation from '@hooks/useTranslation';
import { IMember } from '@types';
import { Breadcrumb, Col, Form, Row, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ActionWrap,
  AvatarWrap,
  CheckBoxWrap,
  Container,
  Description,
  DetailWrap,
  FormHeader,
  Item,
  SearchWrap,
  TableWrap,
  UsernameWrap,
} from './style';
import useEditTeamEditor from './useEditTeamEditor';

const EditTeamEditor: React.FC = () => {
  const t = useTranslation();
  const history = useHistory();
  const {
    form,
    current,
    send,
    onSubmit,
    filterOutSelectedBotMembers,
    handleSelectMembers,
    handlePageChange,
    isCheckBoxDisabled,
  } = useEditTeamEditor();
  const { availableMembers, searchTxt, currentPage, selectedMembers, errorMessage } = current.context;
  const MEMBER_COLUMNS = [
    {
      title: 'USERNAME',
      dataIndex: 'username',
      key: 'username',
      width: 200,
      // eslint-disable-next-line react/display-name
      render: (text: string, record: IMember) => {
        return (
          <UsernameWrap>
            <AvatarWrap>
              <Avatar name={record.username} width={32} height={32} memberStatus={record.status} />
            </AvatarWrap>
            <DetailWrap>
              <Typography.Text>{record.username}</Typography.Text>
              <Typography.Text>{record.email}</Typography.Text>
            </DetailWrap>
          </UsernameWrap>
        );
      },
    },
    {
      title: 'PHONE NO.',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      title: 'CREATE DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (text: string, { createdAt }: { createdAt: string }, index: number) => {
        return createdAt && dayjs(createdAt).format('DD MMM YYYY');
      },
    },
    {
      title: 'ROLE',
      dataIndex: 'role',
      key: 'role',
      render: (text: string, { roles }: { roles: string[] }, index: number) => {
        return roles.join(',');
      },
      width: 200,
    },
  ];

  return (
    <Spin
      spinning={current.matches('fetchingTeamData')}
      indicator={
        <LoadingWrap>
          <LoadingCircle />
        </LoadingWrap>
      }
    >
      <Container>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to="/team-management">{t('livechat.team.management.member.editor.breadcrumb.page.before')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="">
            {t('livechat.team.management.team.editor.breadcrumb.page.current.edit')}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col span={22} offset={2}>
            <Row>
              <FormHeader>
                <Typography.Title level={3}>{t('livechat.team.management.team.editor.edit.title')}</Typography.Title>
              </FormHeader>
              <Form layout="vertical" onFinish={onSubmit} form={form}>
                <Col span={10}>
                  {errorMessage && (
                    <ToastWrap>
                      <DangerToast align="flex-start">{errorMessage}</DangerToast>
                    </ToastWrap>
                  )}

                  <FormItem
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t('livechat.team.management.team.editor.edit.name.required'),
                      },
                    ]}
                    label={
                      <>
                        <LabelTitle>{t('livechat.team.management.team.editor.edit.name.label')}</LabelTitle>
                      </>
                    }
                  >
                    <TextInput
                      size="large"
                      placeholder={t('livechat.team.management.team.editor.edit.name.placeholder')}
                    />
                  </FormItem>
                  <FormItem
                    name="department"
                    label={
                      <>
                        <LabelTitle>{t('livechat.team.management.team.editor.edit.department.label')}</LabelTitle>
                      </>
                    }
                  >
                    <TextInput
                      size="large"
                      placeholder={t('livechat.team.management.team.editor.edit.department.placeholder')}
                    />
                  </FormItem>
                  <CheckBoxWrap>
                    <Item>
                      <FormItem noStyle name="default" valuePropName="checked">
                        <Checkbox onChange={filterOutSelectedBotMembers} />
                      </FormItem>
                    </Item>
                    <Item>
                      <LabelTitle>{t('livechat.team.management.team.editor.edit.set.default.label')}</LabelTitle>
                      <Description>
                        {t('livechat.team.management.team.editor.edit.set.default.label.description')}
                      </Description>
                    </Item>
                  </CheckBoxWrap>
                </Col>
                <Col span={22}>
                  <TableWrap>
                    <Item>
                      <LabelTitle>{t('livechat.team.management.team.editor.edit.add.member.label')}</LabelTitle>
                      <Description>{t('livechat.team.management.team.editor.edit.add.member.description')}</Description>
                    </Item>
                    <SearchWrap>
                      <SearchInput
                        value={searchTxt}
                        size="large"
                        onClear={() => send('SEARCH_TEXT_CHANGE', { value: '' })}
                        onChange={(e: any) => send('SEARCH_TEXT_CHANGE', { value: e.target.value })}
                      />
                    </SearchWrap>
                    <Table
                      scroll={{ y: 'calc(100vh - 350px)' }}
                      columns={MEMBER_COLUMNS}
                      header={<></>}
                      data={(!isEmpty(availableMembers) && availableMembers.rows) ?? []}
                      rowKey={({ _id }: { _id: string }) => _id}
                      rowSelection={{
                        getCheckboxProps: (record: IMember) => {
                          return {
                            disabled: isCheckBoxDisabled(record),
                          };
                        },
                        type: 'checkbox',
                        onChange: handleSelectMembers,
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selectedMembers,
                      }}
                      loading={
                        current.matches('fetchingAvailableMembers') && {
                          indicator: (
                            <LoadingWrap>
                              <LoadingCircle />
                            </LoadingWrap>
                          ),
                        }
                      }
                      pagination={{
                        defaultPageSize: MEMBER_LIST_PAGE_SIZE,
                        current: currentPage,
                        total: !isEmpty(availableMembers) ? availableMembers.total : 1,
                        onChange: handlePageChange,
                        showSizeChanger: false,
                      }}
                    />
                  </TableWrap>
                </Col>
                <ActionWrap>
                  <Button onClick={() => history.push('/team-management')} size="large">
                    {t('livechat.team.management.member.editor.edit.back.btn')}
                  </Button>
                  <PrimaryButton htmlType="submit" size="large" disabled={current.matches('saving')}>
                    {current.matches('saving') && <LoadingIcon icon={['fas', 'sync-alt']} />}
                    {t('livechat.team.management.member.editor.edit.submit.btn')}
                  </PrimaryButton>
                </ActionWrap>
              </Form>
            </Row>
          </Col>
        </Row>
      </Container>
    </Spin>
  );
};

export default EditTeamEditor;
