import React from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useQuery } from 'react-query';
export type IFetchWrapProps<T, Y> = {
  filter: Y;
  queryKey?: any[];
  fetchFn: (filter: Y, cancelToken?: CancelTokenSource) => Promise<T>;
  renderFn: ({
    isLoading,
    data,
    isError,
    refetch,
  }: {
    isLoading: boolean;
    data?: T;
    isError: boolean;
    refetch: () => void;
  }) => JSX.Element;
};

const typedMemo: <T>(c: T) => T = React.memo;

export const FetchWrap = typedMemo(<T, Y>({ filter, queryKey, fetchFn, renderFn }: IFetchWrapProps<T, Y>) => {
  const { isLoading, data, isError, refetch } = useQuery({
    queryKey: queryKey,
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const res = fetchFn(filter, source);
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by React Query');
      });
      return res;
    },
    refetchOnWindowFocus: false,
    retry: 0,
  });
  return renderFn({ isLoading, data, isError, refetch });
});
