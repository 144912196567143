import { useCancelTokenSource } from '@hooks/useCancelToken';
import { IFilterPayload } from '@pages/desktop/recents/EngagementUserList/TicketFilter/useTicketFilter';
import { useMember } from '@providers/MemberProvider';
import SocketIO from '@socket';
import { IChannel, ISocketAgentRead, IUser } from '@types';
import { useMachine } from '@xstate/react';
import { useCallback, useEffect, useRef } from 'react';
import userListMachine from './userListMachine';
import {
  ILastMessageSocket,
  ISocketTicketUpdate,
  LoadMoreSearchEvent,
  LoadMoreTaskEvent,
  LoadMoreType,
  RemoveTicketTaskEvent,
  SetOpenChannelSelectorEvent,
  SetToggleSearchEvent,
  UpdateChannelEvent,
  UpdateKeywordEvent,
  UpdateLastMessageFromSocketEvent,
  UpdateStatusEvent,
  UpdateTaskCountEvent,
  UpdateTicketTaskEvent,
  AgentReadEvent,
} from './userListMachine.type';
import { ESocketEventTopics } from '@enums/Socket';
import { ETicketStatus } from '@enums/TicketStatus';
import { privateAction } from '@utils/privateAction';

export type StatusType = 'assignedToMe' | 'followUp' | 'assignedToTeam';

const useUserList = () => {
  const { member } = useMember();
  const searchInputRef = useRef<any>(null);
  const { newCancelTokenSource } = useCancelTokenSource();
  const userListMachineCallback = useCallback(() => {
    return userListMachine(member, newCancelTokenSource);
  }, []);
  const [current, send] = useMachine(userListMachineCallback, {
    devTools: process.env.NODE_ENV === 'development',
  });

  const { taskData, searchData } = current.context;
  const { keyword, channels, status } = current.context.filterParams;
  const userListSocket = useRef<SocketIO | null>(null);
  const ticketCountSocket = useRef<SocketIO | null>(null);

  const isTaskFetching = current.hasTag('fetchingTaskTag');
  const isTaskNoMore = taskData.total <= taskData.results.length;
  const isTaskLoadingMore = taskData.results.length > 0 && current.hasTag('fetchingTaskTag');
  const isTaskFetchFailed = current.matches('userListState.fetchedTicketTaskFailedState');

  const isUserSearchFetching = current.hasTag('fetchingUserSearchTag');
  const isUserSearchNoMore = searchData.userList.total.value <= searchData.userList.userMessages.length;
  const isUserSearchFailed = current.matches('usersAndMessagesState.fetchingUserState.fetchedUserFailedState');

  const isMessageSearchFetching = current.hasTag('fetchingMessageSearchTag');
  const isMessageSearchNoMore = searchData.messageList.total.value <= searchData.messageList.userMessages.length;
  const isMessageSearchFailed = current.matches('usersAndMessagesState.fetchingMessageState.fetchedMessageFailedState');

  useEffect(() => {
    document.addEventListener('touchmove', () => {
      if (searchInputRef.current?.state.focused) {
        searchInputRef.current?.blur();
      }
    });

    return () => {
      document.removeEventListener('touchmove', () => console.log);
    };
  }, [searchInputRef]);

  useEffect(() => {
    const task = current.context.filterParams.status;
    const channels = current.context.filterParams.channels;
    privateAction((token: string) => {
      userListSocket.current = new SocketIO(
        `user-list`,
        {
          nid: member.nid,
          agentName: member.username,
          agentId: member._id,
          channels: channels,
          teams: member.teamId,
          task: task,
        },
        token,
      );
    });

    return () => {
      if (userListSocket.current) {
        userListSocket.current.disconnect();
      }
    };
  }, [keyword, channels, status]);

  useEffect(() => {
    userListSocket.current?.on(ESocketEventTopics.USERLIST_TICKET, onNewTicket);
    userListSocket.current?.on(ESocketEventTopics.USERLIST_LAST_MESSAGE_REALTIME, onLastMessageFromSocket);
    userListSocket.current?.on(ESocketEventTopics.USERLIST_TICKET_REMOVE, onRemoveTicket);
    userListSocket.current?.on(ESocketEventTopics.USERLIST_AGENT_READ, onAgentRead);
  }, [userListSocket.current]);

  useEffect(() => {
    if (ticketCountSocket.current) {
      ticketCountSocket.current?.disconnect();
    }
    privateAction((token: string) => {
      ticketCountSocket.current = new SocketIO(
        `task`,
        {
          nid: member?.nid,
          agentId: member._id,
          teams: member?.teamId,
          channels: channels,
        },
        token,
      );
    });

    ticketCountSocket.current?.on(ESocketEventTopics.USERLIST_FILTER_TICKET, onTicketCountUpdate);

    return () => {
      ticketCountSocket.current?.disconnect();
    };
  }, [channels]);

  const onNewTicket = (data: ISocketTicketUpdate) => {
    if (data.status === ETicketStatus.RESOLVED) {
      handleRemoveTicketTask(data.id);
    } else {
      handleUpdateTicketTask(data);
    }
  };

  const onRemoveTicket = (data: IUser) => {
    handleRemoveTicketTask(data.id);
  };

  const onLastMessageFromSocket = (data: ILastMessageSocket) => {
    handleLastMessageFromSocket(data);
  };

  const onTicketCountUpdate = (payload: IFilterPayload) => {
    handleUpdateTaskCount(payload);
  };

  const onAgentRead = (data: ISocketAgentRead) => {
    handleAgentReadSocket(data);
  };

  const handleAgentReadSocket = (data: ISocketAgentRead) => {
    send('AGENT_READ' as AgentReadEvent['type'], { value: data.id as AgentReadEvent['value'] });
  };

  const handleUpdateTaskCount = (payload: IFilterPayload) => {
    send('UPDATE_TASK_COUNT' as UpdateTaskCountEvent['type'], {
      value: payload as UpdateTaskCountEvent['value'],
    });
  };

  const handleLastMessageFromSocket = (data: ILastMessageSocket) => {
    send('UPDATE_LAST_MESSAGE_FROM_SOCKET' as UpdateLastMessageFromSocketEvent['type'], {
      value: data as UpdateLastMessageFromSocketEvent['value'],
    });
  };

  const handleRemoveTicketTask = (userId: string) => {
    send('REMOVE_TICKET_TASK' as RemoveTicketTaskEvent['type'], {
      value: userId as RemoveTicketTaskEvent['value'],
    });
  };

  const handleUpdateTicketTask = (data: ISocketTicketUpdate) => {
    send('UPDATE_TICKET_TASK' as UpdateTicketTaskEvent['type'], {
      value: data as UpdateTicketTaskEvent['value'],
    });
  };

  const handleLoadMoreTaskData = () => {
    send('LOAD_MORE_TASK' as LoadMoreTaskEvent['type'], {
      value: undefined as LoadMoreTaskEvent['value'],
    });
  };

  const handleLoadMoreSearchData = (type: LoadMoreType) => {
    send('LOAD_MORE_SEARCH' as LoadMoreSearchEvent['type'], {
      value: type as LoadMoreSearchEvent['value'],
    });
  };

  const handleUpdateStatus = (status: StatusType) => {
    send('UPDATE_STATUS' as UpdateStatusEvent['type'], {
      value: status as UpdateStatusEvent['value'],
    });
  };

  const handleUpdateChannels = (channels: IChannel[]) => {
    send('UPDATE_CHANNEL' as UpdateChannelEvent['type'], {
      value: channels as UpdateChannelEvent['value'],
    });
  };

  const handleUpdateKeyword = (keyword: string) => {
    send('UPDATE_KEYWORD' as UpdateKeywordEvent['type'], {
      value: keyword as UpdateKeywordEvent['value'],
    });
  };

  const handleSetOpenChannelSelector = (open: boolean) => {
    send('SET_OPEN_CHANNEL_SELECTOR' as SetOpenChannelSelectorEvent['type'], {
      value: open as SetOpenChannelSelectorEvent['value'],
    });
  };

  const handleToggleSearchBar = (open: boolean) => {
    send('TOGGLE_SEARCH_BAR' as SetToggleSearchEvent['type'], {
      value: open as SetToggleSearchEvent['value'],
    });
  };
  return {
    current,
    searchInputRef,
    isTaskLoadingMore,
    isTaskFetching,
    isTaskFetchFailed,
    isTaskNoMore,
    isUserSearchFetching,
    isUserSearchFailed,
    isUserSearchNoMore,
    isMessageSearchFetching,
    isMessageSearchFailed,
    isMessageSearchNoMore,
    handleUpdateChannels,
    handleUpdateStatus,
    handleUpdateKeyword,
    handleToggleSearchBar,
    handleSetOpenChannelSelector,
    handleLoadMoreSearchData,
    handleLoadMoreTaskData,
  };
};

export default useUserList;
