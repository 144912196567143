import { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import teamManagementControllerMachine from './teamManagementMachine';
import useTranslation from '@hooks/useTranslation';

const useTeamManagementPane = () => {
  const history = useHistory();
  const t = useTranslation();
  const alert = useAlert();
  const [current, send] = useMachine(teamManagementControllerMachine, {
    devTools: process.env.NODE_ENV === 'development' ? true : false,
  });

  useEffect(() => {
    if (current.matches({ fetchData: 'fetchMemberFailed' })) {
      send('STANDBY');
      return;
    }

    if (current.hasTag('deleteFailed')) {
      alert.error(t('livechat.general.error'), {
        timeout: 2000,
      });
      send('STANDBY');
      return;
    }
  }, [current]);

  useEffect(() => {
    //when tab change
    const { selectedTab } = current.context;
    if (selectedTab.key === 'members') {
      send('FETCH_MEMBER');
      return;
    }
    if (selectedTab.key === 'team') {
      send('FETCH_TEAM');
      return;
    }
  }, [current.context.selectedTab]);

  const handleSelectMemberAction = (action: string, record: any) => {
    if (action === 'edit_member') {
      history.push(`/team-management/edit-member/${record._id}`);
      return;
    }

    if (action === 'delete_member') {
      send('DELETE_MEMBER', { data: record });
      return;
    }

    history.push(`/team-management/reset-member-password/${record._id}`);
    return;
  };

  const handleSelectTeamAction = (action: string, record: any) => {
    if (action === 'edit_team') {
      history.push(`/team-management/edit-team/${record._id}`);
      return;
    }

    if (action === 'delete_team') {
      send('DELETE_TEAM', { data: record });
      return;
    }
  };

  const handleMemberPageChange = (page: number, pageSize: number) => {
    send('FETCH_MEMBER', { page });
  };

  const handleTeamPageChange = (page: number, pageSize: number) => {
    send('FETCH_TEAM', { page });
  };

  const onTabClick = (key: string, event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => {
    send('TAB_CHANGE', { value: key });
  };
  return {
    current,
    handleMemberPageChange,
    handleTeamPageChange,
    handleSelectMemberAction,
    handleSelectTeamAction,
    onTabClick,
    send,
  };
};

export default useTeamManagementPane;
