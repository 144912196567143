import React, { useEffect } from 'react';
import Tab from '@components/Tab';
import { FILTER_ITEM_DATA } from '@configs/constants';
import useTranslation from '@hooks/useTranslation';
import ChannelSelector from './ChannelSelector';
import { TaskCount } from './TaskCount';
import {
  Container,
  FullWidthSearchInput,
  SearchInputBox,
  SmallFontAwesome,
  TopbarBox,
  IconContainer,
  TopbarInner,
  ActionContainer,
  SearchActionContainer,
} from './style';
import UserListPane from './UserListPane';
import UserWithSearchPane from './UserWithSearchPane';
import useUserList, { StatusType } from './useUserList';
import Avatar from '@components/Avatar';
import { getSplitName } from '@utils/splitName';
import { useMember } from '@providers/MemberProvider';
import MemberMenu from './MemberMenu';
import { faArrowLeft, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '@hooks/useQueryParams';

const UserList = () => {
  const { member, openMemberMenu, isChangingStatus, toggleMemberMenu, handleChangeMemberStatus, handleLogout } =
    useMember();
  const t = useTranslation();
  const {
    current,
    searchInputRef,
    isTaskLoadingMore,
    isTaskFetching,
    isTaskFetchFailed,
    isTaskNoMore,
    isUserSearchFetching,
    isUserSearchFailed,
    isUserSearchNoMore,
    isMessageSearchFetching,
    isMessageSearchFailed,
    isMessageSearchNoMore,
    handleUpdateChannels,
    handleUpdateKeyword,
    handleUpdateStatus,
    handleSetOpenChannelSelector,
    handleToggleSearchBar,
    handleLoadMoreSearchData,
    handleLoadMoreTaskData,
  } = useUserList();
  const query = useQueryParams();
  const currentTab = query.get('status');
  useEffect(() => {
    if (currentTab !== 'followUp' && currentTab !== 'teamFollowUp' && currentTab !== 'assignedToTeam') {
      history.replace(`?status=${FILTER_ITEM_DATA[0].value}`);
    }
    handleUpdateStatus(currentTab as StatusType);
  }, [currentTab]);
  const { isSearching, isSelectingChannel, taskData, searchData } = current.context;
  const { channels, keyword } = current.context.filterParams;
  const history = useHistory();
  const _renderTopbarContent = () => {
    if (isSearching) {
      return (
        <TopbarInner>
          <div>
            <IconContainer
              onClick={() => {
                handleToggleSearchBar(false);
              }}
            >
              <SmallFontAwesome icon={faArrowLeft} />
            </IconContainer>
          </div>

          <SearchActionContainer>
            <SearchInputBox>
              <FullWidthSearchInput
                ref={searchInputRef}
                autoFocus
                value={keyword}
                onChange={(e) => {
                  handleUpdateKeyword(e.target.value);
                }}
                onClear={() => {
                  handleUpdateKeyword('');
                }}
              />
            </SearchInputBox>
            <IconContainer onClick={toggleMemberMenu}>
              <Avatar name={getSplitName(member.username)} width={24} height={24} fontSize={10} />
            </IconContainer>
          </SearchActionContainer>
        </TopbarInner>
      );
    }
    return (
      <TopbarInner>
        <div>
          <ChannelSelector
            open={isSelectingChannel}
            setOpen={handleSetOpenChannelSelector}
            selected={channels}
            onSelectDone={(values) => {
              handleUpdateChannels(values);
            }}
          />
        </div>
        <ActionContainer>
          <IconContainer
            onClick={() => {
              handleToggleSearchBar(true);
            }}
          >
            <IconContainer>
              <SmallFontAwesome icon={faSearch} />
            </IconContainer>
          </IconContainer>
          <IconContainer onClick={toggleMemberMenu}>
            <Avatar name={getSplitName(member.username)} width={24} height={24} fontSize={10} />
          </IconContainer>
        </ActionContainer>
      </TopbarInner>
    );
  };

  const _renderPane = () => {
    return isSearching ? (
      <UserWithSearchPane
        keyword={keyword}
        searchData={searchData}
        userListStatus={{
          isFetching: isUserSearchFetching,
          isFetchError: isUserSearchFailed,
          isNoMore: isUserSearchNoMore,
        }}
        messageListStatus={{
          isFetching: isMessageSearchFetching,
          isFetchError: isMessageSearchFailed,
          isNoMore: isMessageSearchNoMore,
        }}
        onLoadMore={handleLoadMoreSearchData}
      />
    ) : (
      <UserListPane
        data={taskData.results}
        isFailed={isTaskFetchFailed}
        isLoading={isTaskFetching}
        hasNextPage={!isTaskNoMore}
        isNextPageLoading={isTaskLoadingMore}
        loadMore={handleLoadMoreTaskData}
      />
    );
  };

  const _renderMemberMenu = () => {
    return (
      <MemberMenu
        open={openMemberMenu}
        loading={isChangingStatus}
        handleCloseModal={toggleMemberMenu}
        status={member.status}
        handleChangeMemberStatus={handleChangeMemberStatus}
        handleLogout={handleLogout}
      />
    );
  };

  return (
    <Container>
      <TopbarBox>{_renderTopbarContent()}</TopbarBox>

      <Tab
        centered
        activeKey={currentTab}
        defaultActiveKey={currentTab!}
        onTabClick={(key) => {
          history.replace(`/m/recents?status=${key}`);
        }}
        tabBarStyle={{
          minWidth: 'auto',
        }}
        tabs={[
          {
            key: FILTER_ITEM_DATA[0].value,
            tab: (
              <TaskCount
                tabName={t(FILTER_ITEM_DATA[0].label)}
                taskCount={isSearching ? undefined : taskData.task_count.assignedToMe}
              />
            ),
            children: _renderPane(),
          },
          {
            key: FILTER_ITEM_DATA[1].value,
            tab: (
              <TaskCount
                tabName={t(FILTER_ITEM_DATA[1].label)}
                taskCount={isSearching ? undefined : taskData.task_count.followUp}
              />
            ),
            children: _renderPane(),
          },
          {
            key: FILTER_ITEM_DATA[2].value,
            tab: (
              <TaskCount
                tabName={t(FILTER_ITEM_DATA[2].label)}
                taskCount={isSearching ? undefined : taskData.task_count.teamFollowUp}
              />
            ),
            children: _renderPane(),
          },
          {
            key: FILTER_ITEM_DATA[3].value,
            tab: (
              <TaskCount
                tabName={t(FILTER_ITEM_DATA[3].label)}
                taskCount={isSearching ? undefined : taskData.task_count.assignedToTeam}
              />
            ),
            children: _renderPane(),
          },
        ]}
      />
      {_renderMemberMenu()}
    </Container>
  );
};

export default UserList;
