import { STICKER_PREFIX } from '@configs/constants';
import { EEventType } from '@enums/EventType';
import { EMessageStatus } from '@enums/MessageStatus';
import { EMessageType } from '@enums/MessageType';
import { v4 as uuid } from 'uuid';
import {
  AudioMessage,
  CarouselMessage,
  CombineAllMessage,
  DateMessage,
  FlexMessage,
  ImageMessage,
  LinkMessage,
  OptionMessage,
  StickerMessage,
  TextMessage,
  TicketMessage,
  VideoMessage,
  ImagemapMessage,
  ImageCarousel,
  TemplateFailed,
  PostbackMessage,
} from '@model/MessageItem';
import {
  BOTS,
  CombineINewMessage,
  IAudioMessage,
  IBaseNewMessage,
  ICarouselMessage,
  IFlexMessage,
  IImageCarousel,
  IImageMessage,
  IImagemapMessage,
  ILinkMessage,
  ILocationMessageApi,
  INewMessage,
  IOptionMessage,
  IPostbackMessage,
  IStickerMessage,
  ITextMessage,
  ITicketMessage,
  IVideoMessage,
  UserMessages,
} from '@types';
import { cloneDeep } from 'lodash';
import isUrl from 'is-url';
import { LocationMessage } from '@model/MessageItem/LocationMessage';
import { filterMessageByStatus } from './filterFailedMessages';
import { ESenderType } from '@enums/SenderType';
import dayjs from 'dayjs';
import EChannelType from '@enums/ChannelType';

export const updateMessagesAfterRetrySuccess = (
  messages: CombineAllMessage[],
  newMessage: CombineAllMessage,
  messageIndex: number,
): CombineAllMessage[] => {
  if (!isPersonMessage(newMessage)) return messages;
  messages.splice(messageIndex, 1);
  const sentMessages = filterMessageByStatus(messages, EMessageStatus.SENT);
  const sendingMessages = filterMessageByStatus(messages, EMessageStatus.SENDING);
  const failedMessages = filterMessageByStatus(messages, EMessageStatus.FAILED);
  const sentWithNewMessages = messageGenerator(sentMessages, newMessage);
  const lastResult = messageGenerator([...sentWithNewMessages, ...sendingMessages], failedMessages);
  return lastResult;
};

export const messageGenerator = (
  arr: CombineAllMessage[] = [],
  newMessage: CombineAllMessage | CombineAllMessage[],
): CombineAllMessage[] => {
  try {
    let messages = cloneDeep(arr);
    const lastMessage = messages[messages.length - 1];
    //if many new messages
    if (Array.isArray(newMessage)) {
      const copyNewMessage = cloneDeep(newMessage);
      for (const item of copyNewMessage) {
        messages = messageGenerator(messages, item);
      }
      return messages;
    }

    if (messages.length === 0 && newMessage instanceof TicketMessage) {
      return [
        newMessage,
        new DateMessage({ id: '', createdAt: newMessage.createdDate, messageStatus: EMessageStatus.SENT }),
      ];
    }

    if (messages.length === 0 && !(newMessage instanceof DateMessage)) {
      return [
        new DateMessage({ id: '', createdAt: newMessage.createdDate, messageStatus: EMessageStatus.SENT }),
        newMessage,
      ];
    }

    if (
      !lastMessage?.isSameDay(newMessage.createdDate) &&
      newMessage instanceof TicketMessage &&
      lastMessage instanceof TicketMessage
    ) {
      messages.push(
        ...[
          newMessage,
          new DateMessage({ id: '', createdAt: newMessage.createdDate, messageStatus: EMessageStatus.SENT }),
        ],
      );
      return messages;
    }
    //not same day
    if (!lastMessage?.isSameDay(newMessage.createdDate) && !(newMessage instanceof DateMessage)) {
      messages.push(
        ...[
          new DateMessage({ id: '', createdAt: newMessage.createdDate, messageStatus: EMessageStatus.SENT }),
          newMessage,
        ],
      );
      return messages;
    }

    //date message and date of last message is the same day with new message
    if (newMessage instanceof DateMessage && lastMessage?.isSameDay(newMessage.createdDate)) {
      return messages;
    }
    // not own of lastest message and person message
    if (!lastMessage?.isSelfSender(newMessage.getSender) && newMessage instanceof isPersonMessage(newMessage)) {
      // set new message level to 1
      newMessage.setLevel = 1;
      messages.push(newMessage);
      return messages;
    }
    // last message is not person message
    if (!(lastMessage instanceof isPersonMessage(lastMessage))) {
      messages.push(newMessage);
      return messages;
    }
    // same min, person message and not same person
    if (
      lastMessage.isWithInOneMin(newMessage.createdDate) &&
      newMessage instanceof isPersonMessage(newMessage) &&
      !lastMessage.isSamePersonWith(newMessage.getSenderName)
    ) {
      messages.push(newMessage);
      return messages;
    }

    //same min, person message and retry is false
    if (
      lastMessage.isWithInOneMin(newMessage.createdDate) &&
      newMessage instanceof isPersonMessage(newMessage) &&
      !lastMessage.getRetry
    ) {
      //set new message level to 2
      newMessage.setLevel = 2;
      //message before is text
      if (
        (lastMessage instanceof TextMessage && newMessage instanceof TextMessage) ||
        (lastMessage instanceof PostbackMessage && newMessage instanceof PostbackMessage)
      ) {
        newMessage.setTextType = 'last';
      }
      //message before is last
      if (
        (lastMessage instanceof TextMessage &&
          lastMessage.instanceData.textType === 'last' &&
          newMessage instanceof TextMessage) ||
        (lastMessage instanceof PostbackMessage &&
          lastMessage.instanceData.textType === 'last' &&
          newMessage instanceof PostbackMessage)
      ) {
        lastMessage.setTextType = 'middle';
      }
      messages.push(newMessage);
      return messages;
    }

    //not same min
    if (newMessage instanceof isPersonMessage(newMessage) && !lastMessage.isWithInOneMin(newMessage.createdDate)) {
      // set new message level to 1
      newMessage.setLevel = 1;
      messages.push(newMessage);
      return messages;
    }
    messages.push(newMessage);

    return messages;
  } catch (error) {
    console.trace();
    console.error('error', error);
    return [];
  }
};

export const isPersonMessage = (message: CombineAllMessage) => {
  if (message instanceof AudioMessage) return AudioMessage;
  if (message instanceof VideoMessage) return VideoMessage;
  if (message instanceof ImageMessage) return ImageMessage;
  if (message instanceof LinkMessage) return LinkMessage;
  if (message instanceof CarouselMessage) return CarouselMessage;
  if (message instanceof OptionMessage) return OptionMessage;
  if (message instanceof FlexMessage) return FlexMessage;
  if (message instanceof ImagemapMessage) return ImagemapMessage;
  if (message instanceof LocationMessage) return LocationMessage;
  if (message instanceof ImageCarousel) return ImageCarousel;
  if (message instanceof TemplateFailed) return TemplateFailed;
  if (message instanceof PostbackMessage) return PostbackMessage;
  return TextMessage;
};

export const genTemplatePayload = (
  template: BOTS.ITemplateApi,
  userId: string,
  userSocialId: string,
  channelId: string,
  ticketId: string,
  channelType: EChannelType,
) => {
  return {
    templateId: template.id,
    userId,
    userSocialId,
    channelId,
    ticketId,
    channelType,
  };
};

export const getMessageFromTemplate = (template: BOTS.ITemplateApi, senderName: string) => {
  const trackId = uuid();
  const newMessage = template.messages.map((msg) => {
    const messageItem = getMessage({
      id: uuid(),
      message: getRestMessageFromTemplate(msg, senderName),
      time: dayjs().valueOf(),
    });
    messageItem.setMetadata = {
      trackId,
    };
    return messageItem;
  });

  return {
    newMessage,
    trackId,
  };
};

export const getRestMessageFromTemplate = (message: BOTS.IMessageApi, senderName: string): CombineINewMessage => {
  const baseMessage: IBaseNewMessage = {
    id: message.id,
    eventType: EEventType.MESSAGE,
    sender: ESenderType.AGENT,
    senderName: senderName,
    senderImage: '',
    createdAt: dayjs().valueOf(),
    messageStatus: EMessageStatus.SENDING,
  };
  switch (message.type) {
    case EMessageType.CAROUSEL:
      return {
        ...baseMessage,
        messageType: EMessageType.CAROUSEL,
        items: message.columns.map((column) => {
          return {
            ...column,
            description: column.text ?? '',
            image: column.thumbnailImageUrl ?? '',
            title: column.title ?? '',
            buttons: column.actions.map((action) => action.label ?? ''),
          };
        }),
      };
    case EMessageType.BUTTONS:
      return {
        ...baseMessage,
        messageType: EMessageType.OPTION,
        label: message.text ?? '',
        actions: message.actions.map((action) => {
          return {
            id: action.id ?? '',
            label: action.label ?? '',
          };
        }),
      };
    case EMessageType.IMAGE:
      return {
        ...baseMessage,
        messageType: EMessageType.IMAGE,
        url: message.originalContentUrl ?? '',
      };
    case EMessageType.VIDEO:
      return {
        ...baseMessage,
        messageType: EMessageType.VIDEO,
        url: message.originalContentUrl ?? '',
      };
    case EMessageType.IMAGE_CAROUSEL:
      return {
        ...baseMessage,
        messageType: EMessageType.IMAGE_CAROUSEL,
        columns: message.columns.map((column) => {
          return {
            button: column.action.label ?? '',
            imageUrl: column.imageUrl ?? '',
          };
        }),
      };
    case EMessageType.FLEX:
      return {
        ...baseMessage,
        messageType: EMessageType.FLEX,
        contents: message.contents,
        altText: message.altText ?? '',
      };
    case EMessageType.IMAGEMAP:
      return {
        ...baseMessage,
        messageType: EMessageType.IMAGEMAP,
        url: message.baseUrl ?? '',
        label: message.altText ?? '',
      };
    default:
      const msg = message as BOTS.ITextMessageApi;
      return {
        ...baseMessage,
        messageType: EMessageType.TEXT,
        text: msg.text,
      };
  }
};

export const getMessage = (newMessage: UserMessages) => {
  if (newMessage.message.eventType === EEventType.MESSAGE) {
    if (newMessage.message.messageType === EMessageType.CAROUSEL) {
      return generateCarousel(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.OPTION) {
      return generateOption(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.AUDIO) {
      return generateAudio(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.VIDEO) {
      return generateVideo(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.IMAGE) {
      return generateImage(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.LINK) {
      return generateLink(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.IMAGE_CAROUSEL) {
      return generateImageCarousel(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.FLEX) {
      return generateFlex(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.IMAGEMAP) {
      return genereateImagemap(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.LOCATION) {
      return generateLocation(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.POSTBACK) {
      return generatePostbackMessage(newMessage);
    }

    if (newMessage.message.messageType === EMessageType.TEXT && newMessage.message.text.includes(STICKER_PREFIX)) {
      return generateStickerMessage(newMessage);
    }

    return generateTextMessage(newMessage);
  } else {
    return generateTicket(newMessage);
  }
};

export const checkVideoLink = (message: string) => {
  const ytRegex = /^(?:https?:\/\/)(?:www\.)?(?:youtube\.com\/(?:watch(?:\?[^#\s]*)?)|youtu\.be\/(\w+))(?:\S+)?$/;
  // youtube
  if (ytRegex.test(message)) return true;
  return false;
};

export const checkLink = (message: string) => {
  return isUrl(message);
};

const generatePostbackMessage = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<IPostbackMessage>;
  return new PostbackMessage({
    ...message,
    id: rawMessage.id,
    text: message.text,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    type: 'first',
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateTextMessage = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<ITextMessage>;
  return new TextMessage({
    ...message,
    id: rawMessage.id,
    text: message.text,
    type: 'first',
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateStickerMessage = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<IStickerMessage>;
  return new StickerMessage({
    ...message,
    id: rawMessage.id,
    text: message.text,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateFlex = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<IFlexMessage>;
  return new FlexMessage({
    ...message,
    id: rawMessage.id,
    contents: message.contents,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateLink = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<ILinkMessage>;
  return new LinkMessage({
    ...message,
    id: rawMessage.id,
    url: message.url,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateCarousel = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<ICarouselMessage>;
  return new CarouselMessage({
    ...message,
    id: rawMessage.id,
    items: message.items,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateOption = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<IOptionMessage>;
  return new OptionMessage({
    ...message,
    id: rawMessage.id,
    label: message.label,
    actions: message.actions,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateVideo = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<IVideoMessage>;
  return new VideoMessage({
    ...message,
    id: rawMessage.id,
    url: message.url,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateAudio = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<IAudioMessage>;
  return new AudioMessage({
    ...message,
    id: rawMessage.id,
    url: message.url,
    createdAt: rawMessage.time,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateImage = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<IImageMessage>;
  return new ImageMessage({
    ...message,
    id: rawMessage.id,
    url: message.url,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateImageCarousel = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<IImageCarousel>;
  return new ImageCarousel({
    ...message,
    id: rawMessage.id,
    columns: message.columns,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateTicket = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<ITicketMessage>;
  return new TicketMessage({
    ...message,
    id: rawMessage.id,
    createdAt: rawMessage.time,
    text: message.text,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const genereateImagemap = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<IImagemapMessage>;
  return new ImagemapMessage({
    ...message,
    id: rawMessage.id,
    url: message.url,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};

const generateLocation = (rawMessage: UserMessages) => {
  const message = rawMessage.message as INewMessage<ILocationMessageApi>;
  return new LocationMessage({
    ...message,
    id: rawMessage.id,
    text: message.text,
    createdAt: rawMessage.time,
    senderImage: message.senderImage,
    messageStatus: message.status ? message.status : EMessageStatus.SENT,
  });
};
