import { DEBOUNCE_TIME } from '@configs/constants';
import { IMember, ITicket } from '@types';
import { CancelTokenSource } from 'axios';
import { compact, keyBy, merge, values } from 'lodash';
import { assign, createMachine } from 'xstate';
import { isUserListInvokeEvent } from './fns/eventChecker';
import { getAllTickets } from './fns/getAllTickets';
import { getMessagesBySearch, getUsersBySearch } from './fns/getUsersAndMessagesBySearch';
import { IUserListMachineContext, UserListEvent, UserTaskItem } from './userListMachine.type';
import UserList from '@model/UserList';
import { ESenderType } from '@enums/SenderType';
const userListMachine = (member: IMember, newCancelTokenSource: () => CancelTokenSource) => {
  return createMachine<IUserListMachineContext, UserListEvent>(
    {
      context: {
        isSelectingChannel: false,
        isSearching: false,

        filterParams: {
          status: 'assignedToMe',
          channels: [],
          keyword: '',
          member,
        },
        taskData: {
          total: 0,
          task_count: {
            assignedToMe: 0,
            assignedToTeam: 0,
            followUps: 0,
            unAssigned: 0,
          },
          results: [],
        },
        searchData: {
          userList: {
            total: {
              relation: '',
              value: 0,
            },
            userMessages: [],
          },
          messageList: {
            total: {
              relation: '',
              value: 0,
            },
            userMessages: [],
          },
        },
      },
      type: 'parallel',
      states: {
        userListState: {
          initial: 'fetchingTicketTaskState',
          states: {
            fetchedChannelFailedState: {},
            fetchingTicketTaskState: {
              tags: 'fetchingTaskTag',
              invoke: {
                src: (context) => getAllTickets(context, newCancelTokenSource()),
                onDone: {
                  target: `fetchedTicketTaskSuccessState`,
                  actions: 'updateTicketTaskDataAction',
                },
                onError: {
                  target: `fetchedTicketTaskFailedState`,
                  actions: [`resetTicketTaskAction`, 'resetTicketCountAction'],
                },
              },
            },
            fetchedTicketTaskSuccessState: {},
            fetchedTicketTaskFailedState: {},
          },
        },
        usersAndMessagesState: {
          initial: 'idle',
          states: {
            idle: {},
            debounceFetchingUsersAndMessagesState: {
              tags: ['fetchingUserSearchTag', 'fetchingMessageSearchTag'],
              after: {
                [DEBOUNCE_TIME]: {
                  target: `fetchingUsersAndMessagesState`,
                },
              },
            },
            fetchingUsersAndMessagesState: {
              type: 'parallel',
              states: {
                fetchingUserState: {
                  initial: 'runFetchingUserState',
                  states: {
                    runFetchingUserState: {
                      tags: 'fetchingUserSearchTag',
                      invoke: {
                        src: getUsersBySearch,
                        onDone: {
                          actions: 'updateUserSearchDataAction',
                          target: `fetchedUserSuccessState`,
                        },
                        onError: {
                          target: `fetchedUserFailedState`,
                        },
                      },
                    },
                    fetchedUserSuccessState: {},
                    fetchedUserFailedState: {},
                  },
                },
                fetchingMessageState: {
                  initial: 'runFetchingMessageState',
                  states: {
                    runFetchingMessageState: {
                      tags: 'fetchingMessageSearchTag',
                      invoke: {
                        src: getMessagesBySearch,
                        onDone: {
                          actions: 'updateMessageSearchDataAction',
                          target: 'fetchedMessageSuccessState',
                        },
                        onError: {
                          target: 'fetchedMessageFailedState',
                        },
                      },
                    },
                    fetchedMessageSuccessState: {},
                    fetchedMessageFailedState: {},
                  },
                },
              },
            },
            fetchingUserState: {
              initial: 'runFetchingUserState',
              states: {
                runFetchingUserState: {
                  tags: 'fetchingUserSearchTag',
                  invoke: {
                    src: getUsersBySearch,
                    onDone: {
                      actions: 'updateUserSearchDataAction',
                      target: `fetchedUserSuccessState`,
                    },
                    onError: {
                      target: `fetchedUserFailedState`,
                    },
                  },
                },
                fetchedUserSuccessState: {},
                fetchedUserFailedState: {},
              },
            },
            fetchingMessageState: {
              initial: 'runFetchingMessageState',
              states: {
                runFetchingMessageState: {
                  tags: 'fetchingMessageSearchTag',
                  invoke: {
                    src: getMessagesBySearch,
                    onDone: {
                      actions: 'updateMessageSearchDataAction',
                      target: 'fetchedMessageSuccessState',
                    },
                    onError: {
                      target: 'fetchedMessageFailedState',
                    },
                  },
                },
                fetchedMessageSuccessState: {},
                fetchedMessageFailedState: {},
              },
            },
          },
        },
      },
      on: {
        AGENT_READ: {
          actions: 'agentReadAction',
        },
        UPDATE_KEYWORD: [
          {
            target: 'usersAndMessagesState.debounceFetchingUsersAndMessagesState',
            actions: ['resetSearchDataAction', 'updateKeywordAction'],
            cond: (context) => context.isSearching,
          },
        ],
        UPDATE_CHANNEL: [
          {
            target: 'userListState.fetchingTicketTaskState',
            actions: ['resetTicketTaskAction', 'updateChannelAction'],
            cond: (context) => !context.isSearching,
          },
          {
            target: 'usersAndMessagesState.fetchingUsersAndMessagesState',
            actions: ['resetSearchDataAction', 'updateChannelAction'],
            cond: (context) => context.isSearching,
          },
        ],
        UPDATE_STATUS: [
          {
            target: `userListState.fetchingTicketTaskState`,
            actions: ['resetTicketTaskAction', 'updateStatusAction'],
            cond: (context) => !context.isSearching,
          },
          {
            target: 'usersAndMessagesState.fetchingUsersAndMessagesState',
            actions: ['resetSearchDataAction', 'updateStatusAction'],
            cond: (context) => context.isSearching,
          },
        ],
        LOAD_MORE_SEARCH: [
          {
            target: `usersAndMessagesState.fetchingUserState`,
            cond: (_, event) => {
              if (!isUserListInvokeEvent(event) && event.type === 'LOAD_MORE_SEARCH') {
                return event.value === 'user';
              }
              return false;
            },
          },
          {
            target: `usersAndMessagesState.fetchingMessageState`,
            cond: (_, event) => {
              if (!isUserListInvokeEvent(event) && event.type === 'LOAD_MORE_SEARCH') {
                return event.value === 'message';
              }
              return false;
            },
          },
        ],
        LOAD_MORE_TASK: {
          target: `userListState.fetchingTicketTaskState`,
        },
        TOGGLE_SEARCH_BAR: {
          actions: 'setIsSearchingAction',
        },
        SET_OPEN_CHANNEL_SELECTOR: {
          actions: 'setIsSelectingChannelAction',
        },
        REMOVE_TICKET_TASK: {
          actions: 'removeTicketTaskAction',
        },
        UPDATE_TICKET_TASK: {
          actions: 'updateTicketTaskAction',
        },
        UPDATE_LAST_MESSAGE_FROM_SOCKET: {
          actions: 'updateLastMessageFromSocketAction',
        },
        UPDATE_TASK_COUNT: {
          actions: 'updateTaskCountAction',
        },
      },
    },
    {
      actions: {
        agentReadAction: assign({
          taskData: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'AGENT_READ') {
              const userId = event.value;
              const tasks = context.taskData.results;
              for (let i = 0; tasks.length; i++) {
                const task = tasks[i];
                if (userId === task.user._id) {
                  task.isRead = true;
                  tasks[i] = task;
                  break;
                }
              }
              return {
                ...context.taskData,
                results: tasks,
              };
            } else {
              return context.taskData;
            }
          },
        }),
        updateChannelDataAction: assign({
          filterParams: (context, event) => {
            if (isUserListInvokeEvent(event) && event.data.type === 'FETCH_CHANNEL_DATA') {
              const data = event.data.value.map((channel) => channel);
              return {
                ...context.filterParams,
                channels: compact(data),
              };
            } else {
              return context.filterParams;
            }
          },
        }),
        updateTicketTaskDataAction: assign({
          taskData: (context, event) => {
            if (isUserListInvokeEvent(event) && event.data.type === 'FETCH_TASK_DATA') {
              const { results } = event.data.value;
              const newResults: UserTaskItem[] = results.map(({ message, ...rest }) => {
                let isRead = false;
                if (message.payload.sender === ESenderType.AGENT || message.payload.sender === ESenderType.SYSTEM) {
                  isRead = true;
                }
                return {
                  ...rest,
                  message,
                  isRead,
                };
              });
              return {
                ...event.data.value,
                results: newResults,
              };
            } else if (isUserListInvokeEvent(event) && event.data.type === 'CONCAT_TASK_DATA') {
              const oldTasks: UserTaskItem[] = context.taskData.results;
              const { total, results } = event.data.value;
              const oldTasksById = keyBy(oldTasks, (t) => t.user._id);
              const newTasksById = keyBy(results, (t) => t.user._id);
              const mergedTasksById = merge(oldTasksById, newTasksById);
              const upsertedTasks = values(mergedTasksById);
              return {
                ...context.taskData,
                results: upsertedTasks,
                total,
              };
            } else {
              return context.taskData;
            }
          },
        }),
        updateUserSearchDataAction: assign({
          searchData: (context, event) => {
            if (isUserListInvokeEvent(event) && event.data.type === 'FETCH_USERS_SEARCH_DATA') {
              return {
                ...context.searchData,
                userList: event.data.value,
              };
            } else if (isUserListInvokeEvent(event) && event.data.type === 'CONCAT_USERS_SEARCH_DATA') {
              const oldUserMsgs = context.searchData.userList.userMessages;
              const { total, userMessages } = event.data.value;
              return {
                ...context.searchData,
                userList: {
                  total,
                  userMessages: oldUserMsgs.concat(userMessages),
                },
              };
            } else {
              return context.searchData;
            }
          },
        }),
        updateMessageSearchDataAction: assign({
          searchData: (context, event) => {
            if (isUserListInvokeEvent(event) && event.data.type === 'FETCH_MESSAGES_SEARCH_DATA') {
              return {
                ...context.searchData,
                messageList: event.data.value,
              };
            } else if (isUserListInvokeEvent(event) && event.data.type === 'CONCAT_MESSAGES_SEARCH_DATA') {
              const oldUserMsgs = context.searchData.messageList.userMessages;
              const { total, userMessages } = event.data.value;
              return {
                ...context.searchData,
                messageList: {
                  total,
                  userMessages: oldUserMsgs.concat(userMessages),
                },
              };
            } else {
              return context.searchData;
            }
          },
        }),
        resetSearchDataAction: assign({
          searchData: (_, _1) => {
            return {
              messageList: {
                total: {
                  relation: '',
                  value: 0,
                },
                userMessages: [],
              },
              userList: {
                total: {
                  relation: '',
                  value: 0,
                },
                userMessages: [],
              },
            };
          },
        }),
        updateKeywordAction: assign({
          filterParams: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'UPDATE_KEYWORD') {
              return {
                ...context.filterParams,
                keyword: event.value,
              };
            } else {
              return context.filterParams;
            }
          },
        }),
        updateStatusAction: assign({
          filterParams: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'UPDATE_STATUS') {
              return {
                ...context.filterParams,
                status: event.value,
              };
            } else {
              return context.filterParams;
            }
          },
        }),
        updateChannelAction: assign({
          filterParams: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'UPDATE_CHANNEL') {
              return {
                ...context.filterParams,
                channels: event.value,
              };
            } else {
              return context.filterParams;
            }
          },
        }),
        setIsSelectingChannelAction: assign({
          isSelectingChannel: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'SET_OPEN_CHANNEL_SELECTOR') {
              return event.value;
            } else {
              return context.isSelectingChannel;
            }
          },
        }),
        setIsSearchingAction: assign({
          isSearching: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'TOGGLE_SEARCH_BAR') {
              return event.value;
            } else {
              return context.isSearching;
            }
          },
        }),
        resetTicketTaskAction: assign({
          taskData: (context, _1) => {
            return {
              ...context.taskData,
              results: [],
              total: 0,
            };
          },
        }),
        resetTicketCountAction: assign({
          taskData: (context, _1) => {
            return {
              ...context.taskData,
              task_count: {
                assignedToMe: 0,
                assignedToTeam: 0,
                followUps: 0,
                unAssigned: 0,
              },
            };
          },
        }),
        removeTicketTaskAction: assign({
          taskData: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'REMOVE_TICKET_TASK') {
              const tasks = context.taskData;
              return {
                ...tasks,
                results: tasks.results.filter((task) => task.user._id !== event.value),
              };
            } else {
              return context.taskData;
            }
          },
        }),
        updateTicketTaskAction: assign({
          taskData: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'UPDATE_TICKET_TASK') {
              const tasks = context.taskData.results;
              const data = event.value;
              const index = tasks.findIndex(({ user }) => user._id === data.id);
              if (index > -1) {
                const task = tasks[index];
                task.isRead = data.isRead;
                task.user.message = UserList.getDescriptionMessage(data.message.messageType, data.message.text ?? '');
                tasks[index] = task;
              } else {
                tasks.push({
                  isRead: data.isRead,
                  ticket: {} as ITicket,
                  user: {
                    _id: data.id,
                    image: data.img,
                    message: UserList.getDescriptionMessage(data.message.messageType, data.message.text ?? ''),
                    name: data.name,
                  },
                  message: {
                    createdAt: new Date(data.time).valueOf(),
                    channelType: data.channelType,
                    payload: data.message,
                  },
                });
              }
              return {
                ...context.taskData,
                results: tasks,
              };
            } else {
              return context.taskData;
            }
          },
        }),
        updateLastMessageFromSocketAction: assign({
          taskData: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'UPDATE_LAST_MESSAGE_FROM_SOCKET') {
              const tasks = context.taskData.results;
              const index = tasks.findIndex(({ user }) => user._id === event.value.userId);
              const lastMessage = event.value;
              if (index > -1) {
                const task = tasks[index];
                task.user.message = UserList.getDescriptionMessage(
                  lastMessage.payload.messageType,
                  lastMessage.payload.text ?? '',
                );
                task.isRead = lastMessage.payload.sender === 'agent';
                task.message = lastMessage;
                // swap user to top
                // remove user from list
                tasks.splice(index, 1);
                // add user to top
                tasks.unshift(task);
                return {
                  ...context.taskData,
                  results: tasks,
                };
              }
            }
            return context.taskData;
          },
        }),
        updateTaskCountAction: assign({
          taskData: (context, event) => {
            if (!isUserListInvokeEvent(event) && event.type === 'UPDATE_TASK_COUNT') {
              const taskCount = context.taskData.task_count;
              const incomingCount = event.value;
              const assignedToMe = taskCount.assignedToMe + incomingCount.assignedToMe;
              const assignedToTeam = taskCount.assignedToTeam + incomingCount.assignedToTeam;
              const followUps = taskCount.followUps + incomingCount.followUps;
              const unAssigned = taskCount.unAssigned + incomingCount.unAssigned;
              return {
                ...context.taskData,
                task_count: {
                  assignedToMe,
                  assignedToTeam,
                  followUps,
                  unAssigned,
                },
              };
            }
            return context.taskData;
          },
        }),
      },
    },
  );
};

export default userListMachine;
