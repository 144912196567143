import React, { useMemo } from 'react';
import { Typography } from 'antd';
import useTranslation from '@hooks/useTranslation';
import {
  Container,
  HeaderBox,
  ActionWrap,
  ActionBox,
  FilterGroup,
  SearchWrap,
  RoleFilter,
  ContentBox,
  LoadingWrap,
} from './style';
import SearchInput from '@components/SearchInput';
import Select from '@components/Select';
import { TEAM_MANAGEMENT_ROLES_FILTER, TEAM_MANAGEMENT_TABS_DATA } from '@configs/constants';
import { PrimaryButton, Button } from '@components/Button';
import { DeleteModal } from '@components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTeamManagementPane from './useTeamManagementPane';
import { Link } from 'react-router-dom';
import Tab from '@components/Tab';
import TeamList from './TeamList';
import StateMessage from '@components/StateMessage';
import AddPerson from '@assets/images/add-person.svg';
import error from '@assets/images/error.svg';
import { Font16G1W400, Font20G1W600 } from '@components/UtilsComponent';

const TeamManagementPane: React.FC = () => {
  const t = useTranslation();
  const { current, onTabClick, handleTeamPageChange, handleSelectTeamAction, send } = useTeamManagementPane();
  const {
    selectedTab,
    memberData,
    teamData,
    selectedRole,
    teamCurrentPage,
    memberCurrentPage,
    searchTxt,
    selectedMember,
    selectedTeam,
  } = current.context;

  const _renderTeamList = () => {
    if (current.matches({ fetchData: 'fetchTeamFailed' })) {
      return (
        <StateMessage
          size="large"
          type="error"
          img={error}
          label={t('livechat.error.title')}
          description={t('livechat.error.description')}
        />
      );
    }
    if (teamData.total === 0) {
      return (
        <StateMessage
          img={AddPerson}
          type="general"
          size="large"
          label={t('livechat.team.management.team.list.empty.title')}
          description={t('livechat.team.management.team.list.empty.description')}
        />
      );
    }
    return (
      <TeamList
        teamData={teamData}
        teamCurrentPage={teamCurrentPage}
        isLoading={current.matches({ fetchData: 'fetchingTeam' })}
        handleSelectAction={(item: { label: string; value: string }, record: any) =>
          handleSelectTeamAction(item.value, record)
        }
        handlePageChange={handleTeamPageChange}
      />
    );
  };

  const TabList = useMemo(() => {
    return TEAM_MANAGEMENT_TABS_DATA.map((item) => {
      return {
        ...item,
        tab: item.name,
        children: _renderTeamList(),
      };
    });
  }, [
    memberData,
    teamData,
    current.matches({ fetchData: 'firstFetch' }),
    current.matches({ fetchData: 'fetchingMember' }),
    current.matches({ fetchData: 'fetchMemberFailed' }),
    current.matches({ fetchData: 'fetchingTeam' }),
    memberCurrentPage,
  ]);

  return (
    <Container>
      <HeaderBox>
        <Typography.Title level={3}>{t('livechat.team.management.header.title')}</Typography.Title>
        <ActionWrap>
          <FilterGroup>
            <SearchWrap>
              <SearchInput
                value={searchTxt}
                size="large"
                onClear={() => send('SEARCH_TEXT_CHANGE', { searchTxt: '' })}
                onChange={(e) => send('SEARCH_TEXT_CHANGE', { searchTxt: e.target.value })}
              />
            </SearchWrap>
          </FilterGroup>
          <ActionBox>
            <Link to="/team-management/new-team">
              <PrimaryButton icon={<FontAwesomeIcon icon={['fas', 'users']} />} size="large">
                {t('livechat.team.management.add.new.team.btn')}
              </PrimaryButton>
            </Link>
          </ActionBox>
        </ActionWrap>
      </HeaderBox>
      <ContentBox>
        <Tab tabs={TabList} defaultActiveKey={TEAM_MANAGEMENT_TABS_DATA[0].key} onTabClick={onTabClick} />
      </ContentBox>
      <DeleteModal
        visible={current.matches({ fetchData: 'deleteMember' }) || current.matches({ fetchData: 'deletingMember' })}
        isLoading={current.matches({ fetchData: 'deletingMember' })}
        handleConfirm={() => send('CONFIRM_DELETE_MEMBER')}
        handleClose={() => send('CANCEL_DELETE_MEMBER')}
        cancelBtnTxt={t('livechat.team.management.modal.delete.member.cancel.button')}
        confirmBtnTxt={t('livechat.team.management.modal.delete.member.confirm.button')}
      >
        <span>{t('livechat.team.management.modal.delete.member.title', { username: selectedMember.username })}</span>
      </DeleteModal>
      <DeleteModal
        visible={current.matches({ fetchData: 'deleteTeam' }) || current.matches({ fetchData: 'deletingTeam' })}
        isLoading={current.matches({ fetchData: 'deletingTeam' })}
        handleConfirm={() => send('CONFIRM_DELETE_TEAM')}
        handleClose={() => send('CANCEL_DELETE_TEAM')}
        cancelBtnTxt={t('livechat.team.management.modal.delete.team.cancel.button')}
        confirmBtnTxt={t('livechat.team.management.modal.delete.team.confirm.button')}
      >
        <Font20G1W600>
          {t('livechat.team.management.modal.delete.team.title', {
            teamName: selectedTeam.name,
          })}
        </Font20G1W600>
        <Font16G1W400>{t('livechat.team.management.modal.delete.team.description')}</Font16G1W400>
      </DeleteModal>
    </Container>
  );
};

export default TeamManagementPane;
