import React from 'react';
import { Container, ToastContainer } from './style';
import useComposer from './useComposer';
import MessageComposer from '@pages/components/MessageComposer';
import { InfoToast } from '@components/Toast';
import useTranslation from '@hooks/useTranslation';
import { useTicketManager } from '@providers/TicketManagerProvider';
import { IUser } from '@types';

export type IMobileComposerProps = {
  selectedUser: IUser;
};

const Composer: React.FC<IMobileComposerProps> = ({ selectedUser }) => {
  const t = useTranslation();
  const { fileHandler, messageBoxHandler } = useComposer();
  const { canChat } = useTicketManager();

  return (
    <Container>
      {!canChat() ? (
        <ToastContainer>
          <InfoToast align="flex-start">{t('livechat-composer-info-not-my-own-ticket')}</InfoToast>
        </ToastContainer>
      ) : (
        <div>
          <MessageComposer
            platform={selectedUser?.channel?.type.toLocaleLowerCase()}
            messageBoxHandler={messageBoxHandler}
            fileHandler={fileHandler}
          />
        </div>
      )}
    </Container>
  );
};

export default Composer;
